import { Clock, CheckCircle, AlertTriangle, Loader } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';

interface LogEntry {
  timestamp: Date;
  type: 'info' | 'success' | 'error';
  message: string;
  data?: any;
}

interface AnalysisLogProps {
  logs: LogEntry[];
  isAnalyzing: boolean;
}

export function AnalysisLog({ logs, isAnalyzing }: AnalysisLogProps) {
  if (logs.length === 0 && !isAnalyzing) return null;

  return (
    <div className="mt-6 bg-surface-secondary rounded-lg p-4">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-sm font-medium text-text-primary">Analysis Log</h3>
        {isAnalyzing && (
          <div className="flex items-center space-x-2 text-text-secondary text-sm">
            <Clock className="w-4 h-4" />
            <span>Analysis in progress...</span>
          </div>
        )}
      </div>

      <div className="space-y-3">
        {logs.map((log, index) => (
          <div key={index} className="flex items-start space-x-3 text-sm">
            {log.type === 'success' && (
              <CheckCircle className="w-4 h-4 text-green-500 mt-0.5" />
            )}
            {log.type === 'error' && (
              <AlertTriangle className="w-4 h-4 text-red-500 mt-0.5" />
            )}
            {log.type === 'info' && (
              <Loader className="w-4 h-4 text-cream animate-spin mt-0.5" />
            )}
            
            <div className="flex-1 min-w-0">
              <p className={
                log.type === 'success' ? 'text-green-500' :
                log.type === 'error' ? 'text-red-500' :
                'text-text-primary'
              }>
                {log.message}
              </p>
              {log.data && (
                <pre className="mt-1 text-xs text-text-secondary bg-surface/50 rounded p-2 overflow-x-auto">
                  {JSON.stringify(log.data, null, 2)}
                </pre>
              )}
              <span className="text-xs text-text-secondary">
                {formatDistanceToNow(log.timestamp, { addSuffix: true })}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}