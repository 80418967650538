export const VIDEO_CONSTRAINTS = {
  MAX_SIZE_BYTES: 2 * 1024 * 1024 * 1024, // 2GB
  ALLOWED_MIME_TYPES: ['video/mp4', 'video/webm', 'video/quicktime'],
  MAX_DURATION_SECONDS: 180 // 3 minutes
} as const;

export const ERROR_MESSAGES = {
  INVALID_URL: 'Invalid video URL provided',
  FILE_TOO_LARGE: 'Video file exceeds maximum size limit',
  INVALID_FORMAT: 'Invalid video format',
  DOWNLOAD_FAILED: 'Failed to download video',
  NO_URL_AVAILABLE: 'No valid video URL available',
  NO_DROPBOX_URL: 'No Dropbox URL available for analysis',
  VALIDATION_FAILED: 'Video validation failed'
} as const;