import { useState } from 'react';
import { RefreshCw, Loader } from 'lucide-react';
import { hot50ImportService } from '../../services/hot50/import';
import { useHot50Store } from '../../store/hot50';
import { loggingService } from '../../services/logging';

export function UpdateHot50Button() {
  const [isUpdating, setIsUpdating] = useState(false);
  const fetchEntries = useHot50Store(state => state.fetchEntries);

  const handleUpdate = async () => {
    if (isUpdating) return;

    setIsUpdating(true);
    try {
      await hot50ImportService.updateHot50();
      await fetchEntries(); // Refresh the list after update

      loggingService.addLog({
        type: 'success',
        message: 'Hot50 list updated successfully'
      });
    } catch (error) {
      loggingService.addLog({
        type: 'error',
        message: 'Failed to update Hot50',
        data: error
      });
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <button
      onClick={handleUpdate}
      disabled={isUpdating}
      className="flex items-center space-x-2 px-4 py-2 bg-cream text-stone-dark rounded-lg hover:bg-cream-dark transition-colors disabled:opacity-50"
    >
      {isUpdating ? (
        <>
          <Loader className="w-4 h-4 animate-spin" />
          <span>Updating...</span>
        </>
      ) : (
        <>
          <RefreshCw className="w-4 h-4" />
          <span>Update Hot50</span>
        </>
      )}
    </button>
  );
}