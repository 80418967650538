import { GoogleGenerativeAI } from '@google/generative-ai';
import { loggingService } from '../logging';
import { formatDropboxUrl } from '../../lib/video/url-processor';

const GEMINI_API_KEY = import.meta.env.VITE_GOOGLE_AI_API_KEY;
const genAI = new GoogleGenerativeAI(GEMINI_API_KEY);
const model = genAI.getGenerativeModel({ model: 'gemini-1.5-flash' });

const ANALYSIS_PROMPT = `Agieren Sie als Experte für Musikmarketing mit Schwerpunkt auf Viralität auf TikTok. Analysieren Sie das folgende TikTok-Video:

Video URL: {VIDEO_URL}

Beschreiben Sie den Inhalt, die Struktur und die verwendeten Hooks (Text oder gesprochen). Analysieren Sie besonders die Verbindung zwischen dem verwendeten Song und dem Video-Content:

1. Wie passt der Song zum visuellen Content?
2. Welche Lyrics oder musikalischen Elemente werden besonders hervorgehoben?
3. Wie wird die Stimmung des Songs im Video aufgegriffen?
4. Welche Meta-Ebenen oder tieferen Bedeutungen entstehen durch die Kombination?

Ordnen Sie das Video anhand seines Themas in eine Nische ein. Geben Sie auch eine allgemeingültige Anleitung, wie ein Künstler die Mechanik des Videos für sich nutzen oder nachbauen kann, um Reichweite aufzubauen.`;

interface VideoData {
  fileUrl: string | null;
  driveUrl?: string | null;
  videoUrl?: string | null;
  description: string;
  stats: {
    viewCount: number;
    likeCount: number;
    shareCount: number;
    commentCount: number;
  };
  customTheme?: string;
}

interface VideoAnalysisResult {
  content: string;
  mechanic: string;
}

export class GeminiService {
  private static instance: GeminiService;

  private constructor() {}

  static getInstance(): GeminiService {
    if (!GeminiService.instance) {
      GeminiService.instance = new GeminiService();
    }
    return GeminiService.instance;
  }

  async analyzeVideo(data: VideoData): Promise<VideoAnalysisResult> {
    try {
      // Get the Dropbox URL and format it with raw=1
      const videoUrl = data.driveUrl ? formatDropboxUrl(data.driveUrl) : data.fileUrl;

      if (!videoUrl) {
        throw new Error('No valid video URL available for analysis');
      }

      const themePrompt = data.customTheme ? `
Machen Sie einen konkreten Vorschlag, wie die Mechanik des Videos auf das folgende Thema übertragen werden kann:
${data.customTheme}` : '';

      const prompt = `
${ANALYSIS_PROMPT.replace('{VIDEO_URL}', videoUrl)}

Video-Informationen:
- Beschreibung: ${data.description}
- Aufrufe: ${data.stats.viewCount}
- Likes: ${data.stats.likeCount}
- Shares: ${data.stats.shareCount}
- Kommentare: ${data.stats.commentCount}

Bitte analysieren Sie das Video und strukturieren Sie Ihre Analyse in zwei Hauptteile:
1. Content-Analyse (inkl. Song-Integration)
2. Marketing-Mechanik${data.customTheme ? ` (mit Fokus auf das Thema: ${data.customTheme})` : ''}
`;

      loggingService.addLog({
        type: 'info',
        message: 'Starting Gemini analysis',
        data: {
          videoUrl,
          promptLength: prompt.length,
          customTheme: data.customTheme
        }
      });

      const result = await model.generateContent(prompt);
      const response = await result.response;
      const text = response.text();

      const sections = text.split(/\d\.\s+(?:Content-Analyse|Marketing-Mechanik)/i);
      const contentSection = sections[1]?.trim() || 'Keine Content-Analyse verfügbar.';
      const mechanicSection = sections[2]?.trim() || 'Keine Marketing-Mechanik verfügbar.';

      loggingService.addLog({
        type: 'success',
        message: 'Successfully generated AI analysis',
        data: { 
          contentLength: contentSection.length,
          mechanicLength: mechanicSection.length,
          customTheme: data.customTheme
        }
      });

      return {
        content: contentSection,
        mechanic: mechanicSection
      };
    } catch (error) {
      loggingService.addLog({
        type: 'error',
        message: 'Failed to generate AI analysis',
        data: {
          error,
          errorMessage: error instanceof Error ? error.message : 'Unknown error',
          sources: {
            driveUrl: data.driveUrl || 'not available'
          }
        }
      });
      throw error;
    }
  }
}

export const geminiService = GeminiService.getInstance();