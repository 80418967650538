import { format } from 'date-fns';

export function formatDateInput(input: string): string {
  const digits = input.replace(/\D/g, '');
  if (digits.length <= 2) return digits;
  if (digits.length <= 4) return `${digits.slice(0, 2)}.${digits.slice(2)}`;
  return `${digits.slice(0, 2)}.${digits.slice(2, 4)}.${digits.slice(4, 6)}`;
}

export function validateDateFormat(date: string): boolean {
  return /^\d{2}\.\d{2}\.\d{2}$/.test(date);
}

export function formatDisplayDate(date: string): string | null {
  try {
    const [day, month, year] = date.split('.');
    if (!day || !month || !year) return null;
    
    const fullYear = `20${year}`;
    const dateObj = new Date(parseInt(fullYear), parseInt(month) - 1, parseInt(day));
    
    return format(dateObj, 'dd. MMMM yyyy');
  } catch {
    return null;
  }
}