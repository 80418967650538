import { Hot50Entry } from '../../types/hot50';
import { TrackCard } from './TrackCard';
import { TrackCardMobile } from './TrackCardMobile';

interface TrackListProps {
  entries: Hot50Entry[];
  onTrackClick: (rank: number) => void;
  onTikTokClick: (e: React.MouseEvent<HTMLButtonElement>, url: string) => void;
  isMobile: boolean;
}

export function TrackList({ entries, onTrackClick, onTikTokClick, isMobile }: TrackListProps) {
  const Card = isMobile ? TrackCardMobile : TrackCard;

  return (
    <div className="space-y-4">
      {entries.map((entry) => (
        <Card
          key={entry.id}
          entry={entry}
          onTrackClick={onTrackClick}
          onTikTokClick={onTikTokClick}
        />
      ))}
    </div>
  );
}