import { RefreshCw } from 'lucide-react';
import { Descendant } from 'slate';
import { updateCalendarDates } from '../../lib/workbook/calendar/table';

interface ContentCalendarProps {
  value: Descendant[];
  onUpdate: (content: Descendant[]) => void;
  releaseDate: string | null;
}

export function ContentCalendar({ value, onUpdate, releaseDate }: ContentCalendarProps) {
  const handleRefresh = () => {
    if (!releaseDate) return;
    const updatedContent = updateCalendarDates(value, releaseDate);
    onUpdate(updatedContent);
  };

  return (
    <div className="flex items-center space-x-2 mb-4">
      <button
        onClick={handleRefresh}
        className="p-1.5 hover:bg-surface-secondary rounded-lg transition-colors text-text-secondary"
        title="Refresh calendar dates"
      >
        <RefreshCw className="w-4 h-4" />
      </button>
      <span className="text-sm text-text-secondary">
        Click to refresh calendar based on release date
      </span>
    </div>
  );
}