import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Music2 } from 'lucide-react';
import { Hot50Entry } from '../types/hot50';
import { fetchTrackDetails } from '../services/hot50';
import { loggingService } from '../services/logging';
import { ErrorDisplay } from '../components/ErrorDisplay';
import { VideoGrid } from '../components/video/VideoGrid';
import { SortOption } from '../components/video/SortSelect';
import { BackButton } from '../components/BackButton';

export function TrackDetailsPage() {
  const { position } = useParams();
  const [entry, setEntry] = useState<Hot50Entry | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [sortBy, setSortBy] = useState<SortOption>('shares');

  useEffect(() => {
    async function loadTrackDetails() {
      if (!position) return;

      try {
        const rank = parseInt(position);
        const trackDetails = await fetchTrackDetails(rank);
        
        // Filter videos to only include those with drive_url
        const filteredTrackDetails = {
          ...trackDetails,
          videos: trackDetails.videos.filter(video => video.drive_url)
        };
        
        setEntry(filteredTrackDetails);

        loggingService.addLog({
          type: 'success',
          message: `Loaded track details for position ${rank}`,
          data: { 
            track_name: trackDetails.track_name,
            videoCount: filteredTrackDetails.videos.length
          }
        });
      } catch (error) {
        const message = error instanceof Error ? error.message : 'Failed to load track data';
        setError(message);
        
        loggingService.addLog({
          type: 'error',
          message: 'Error fetching track data',
          data: error
        });
      } finally {
        setIsLoading(false);
      }
    }

    loadTrackDetails();
  }, [position]);

  if (isLoading) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="animate-pulse">
          <div className="flex space-x-6 mb-8">
            <div className="w-32 h-32 bg-surface-secondary rounded-lg"></div>
            <div className="flex-1">
              <div className="h-8 bg-surface-secondary rounded w-1/3 mb-4"></div>
              <div className="h-6 bg-surface-secondary rounded w-1/4"></div>
            </div>
          </div>
          <div className="h-64 bg-surface-secondary rounded"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return <ErrorDisplay error={new Error(error)} />;
  }

  if (!entry) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8">
        <p className="text-text-primary">No track found at this position.</p>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <BackButton />
      <div className="flex space-x-6 mb-8">
        <div className="w-32 h-32 rounded-lg overflow-hidden bg-surface-secondary flex-shrink-0">
          {entry.tiktok_artwork_url ? (
            <img
              src={entry.tiktok_artwork_url}
              alt={`${entry.track_name} artwork`}
              className="w-full h-full object-cover"
            />
          ) : (
            <div className="w-full h-full flex items-center justify-center">
              <Music2 className="w-12 h-12 text-text-secondary" />
            </div>
          )}
        </div>
        
        <div className="flex-1">
          <h1 className="text-3xl font-bold text-text-primary mb-2">{entry.track_name}</h1>
          <p className="text-xl text-text-secondary">{entry.artist_name}</p>
        </div>
      </div>

      <VideoGrid 
        videos={entry.videos} 
        sortBy={sortBy}
        onSortChange={setSortBy}
      />
    </div>
  );
}