import { useRef, useState } from 'react';
import { cn } from '../../lib/utils';
import { VideoThumbnail } from './VideoThumbnail';
import { useResponsive } from '../../hooks/useResponsive';

interface VideoPlayerProps {
  src: string | null;
  poster: string | null;
  className?: string;
  onClick?: () => void;
}

export function VideoPlayer({ src, poster, className, onClick }: VideoPlayerProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isHovering, setIsHovering] = useState(false);
  const { isMobile } = useResponsive();

  const handleMouseEnter = () => {
    if (!src || !videoRef.current || isMobile) return;
    setIsHovering(true);
    videoRef.current.play().catch(() => {
      // Ignore play() errors on hover
    });
  };

  const handleMouseLeave = () => {
    if (!videoRef.current || isMobile) return;
    setIsHovering(false);
    videoRef.current.pause();
    videoRef.current.currentTime = 0;
  };

  return (
    <div 
      className={cn(
        "relative aspect-[9/16] bg-surface-secondary rounded-lg overflow-hidden group",
        className,
        onClick && "cursor-pointer"
      )}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
    >
      <VideoThumbnail 
        src={poster}
        videoUrl={src}
        isHidden={isHovering}
      />
    </div>
  );
}