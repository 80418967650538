import { WorkbookUpdate } from './types';
import { updateWorkbook } from './database';
import { loggingService } from '../../services/logging';

const AUTOSAVE_DELAY = 1000; // 1 second

export class AutosaveManager {
  private static instance: AutosaveManager;
  private timeoutId: NodeJS.Timeout | null = null;
  private lastSave: number = 0;

  private constructor() {}

  static getInstance(): AutosaveManager {
    if (!AutosaveManager.instance) {
      AutosaveManager.instance = new AutosaveManager();
    }
    return AutosaveManager.instance;
  }

  scheduleAutosave(id: string, changes: WorkbookUpdate): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    // Only autosave if enough time has passed since last save
    const now = Date.now();
    if (now - this.lastSave < AUTOSAVE_DELAY) {
      this.timeoutId = setTimeout(() => {
        this.save(id, changes);
      }, AUTOSAVE_DELAY);
      return;
    }

    this.save(id, changes);
  }

  private async save(id: string, changes: WorkbookUpdate): Promise<void> {
    try {
      await updateWorkbook(id, changes);
      this.lastSave = Date.now();
      
      loggingService.addLog({
        type: 'success',
        message: 'Workbook autosaved',
        data: { id }
      });
    } catch (error) {
      loggingService.addLog({
        type: 'error',
        message: 'Failed to autosave workbook',
        data: { error, id }
      });
    } finally {
      this.timeoutId = null;
    }
  }

  cancelPendingSave(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }
}

export const autosaveManager = AutosaveManager.getInstance();