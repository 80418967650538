import { supabase } from '../../supabase';
import { Template } from '../types';
import { loggingService } from '../../../services/logging';
import { DEFAULT_ARTIST_TEMPLATE } from './defaults';
import { nanoid } from 'nanoid';

export async function fetchTemplate(id: string): Promise<Template> {
  try {
    const { data, error } = await supabase
      .from('templates')
      .select('*')
      .eq('id', id)
      .single();

    if (error) throw error;
    if (!data) {
      // Return default template if not found in database
      return DEFAULT_ARTIST_TEMPLATE;
    }

    return data;
  } catch (error) {
    loggingService.addLog({
      type: 'error',
      message: 'Failed to fetch template',
      data: { error, id }
    });
    throw error;
  }
}

export async function fetchTemplates(): Promise<Template[]> {
  try {
    const { data, error } = await supabase
      .from('templates')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) throw error;
    return data || [DEFAULT_ARTIST_TEMPLATE];
  } catch (error) {
    loggingService.addLog({
      type: 'error',
      message: 'Failed to fetch templates',
      data: error
    });
    throw error;
  }
}

export async function saveAsTemplate(workbookId: string): Promise<void> {
  try {
    // Get workbook content
    const { data: workbook, error: workbookError } = await supabase
      .from('workbooks')
      .select('content, title')
      .eq('id', workbookId)
      .single();

    if (workbookError) throw workbookError;

    // Update or create template
    const { error: templateError } = await supabase
      .from('templates')
      .upsert({
        ...DEFAULT_ARTIST_TEMPLATE,
        content: workbook.content,
        updated_at: new Date().toISOString()
      });

    if (templateError) throw templateError;

    loggingService.addLog({
      type: 'success',
      message: 'Successfully saved workbook as template',
      data: { workbookId }
    });
  } catch (error) {
    loggingService.addLog({
      type: 'error',
      message: 'Failed to save workbook as template',
      data: { error, workbookId }
    });
    throw error;
  }
}

export async function createFromTemplate(templateId: string = 'artist-workbook'): Promise<string> {
  try {
    // Generate workbook ID
    const workbookId = nanoid();
    const now = new Date().toISOString();

    // Get template content
    const template = await fetchTemplate(templateId);

    // Create new workbook from template
    const { error } = await supabase
      .from('workbooks')
      .insert({
        id: workbookId,
        title: 'Untitled Workbook',
        content: template.content,
        created_at: now,
        updated_at: now
      });

    if (error) throw error;

    loggingService.addLog({
      type: 'success',
      message: 'Created workbook from template',
      data: { templateId, workbookId }
    });

    return workbookId;
  } catch (error) {
    loggingService.addLog({
      type: 'error',
      message: 'Failed to create workbook from template',
      data: { error, templateId }
    });
    throw error;
  }
}