import { useState, useEffect } from 'react';
import { Cloud, Key, Loader, AlertTriangle, CheckCircle, RefreshCw } from 'lucide-react';
import { googleCloudAuth } from '../../lib/video/auth';
import { loggingService } from '../../services/logging';

interface ConfigStatus {
  isConfigured: boolean;
  error: string | null;
  config: {
    projectId: string | null;
    location: string | null;
    modelName: string | null;
  };
  lastChecked: Date | null;
}

export function GoogleCloudStatus() {
  const [status, setStatus] = useState<ConfigStatus>({
    isConfigured: false,
    error: null,
    config: {
      projectId: null,
      location: null,
      modelName: null
    },
    lastChecked: null
  });
  const [isChecking, setIsChecking] = useState(false);

  const checkConfiguration = async () => {
    setIsChecking(true);
    try {
      const config = await googleCloudAuth.getConfig();
      const isValid = await googleCloudAuth.validateConfig();

      setStatus({
        isConfigured: isValid,
        error: null,
        config: {
          projectId: config.projectId,
          location: config.location,
          modelName: config.modelName
        },
        lastChecked: new Date()
      });

      loggingService.addLog({
        type: 'success',
        message: 'Google Cloud configuration verified',
        data: config
      });
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Failed to verify configuration';
      
      setStatus({
        isConfigured: false,
        error: message,
        config: {
          projectId: null,
          location: null,
          modelName: null
        },
        lastChecked: new Date()
      });

      loggingService.addLog({
        type: 'error',
        message: 'Google Cloud configuration check failed',
        data: error
      });
    } finally {
      setIsChecking(false);
    }
  };

  useEffect(() => {
    checkConfiguration();
  }, []);

  return (
    <div className="bg-surface border border-border rounded-lg p-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center space-x-3">
          <Cloud className="w-5 h-5 text-cream" />
          <h3 className="text-lg font-medium text-text-primary">Google Cloud Status</h3>
        </div>
        <button
          onClick={checkConfiguration}
          disabled={isChecking}
          className="p-2 hover:bg-surface-secondary rounded-lg transition-colors disabled:opacity-50"
          title="Refresh status"
        >
          <RefreshCw className={`w-4 h-4 text-text-secondary ${isChecking ? 'animate-spin' : ''}`} />
        </button>
      </div>

      <div className="space-y-4">
        <div className="flex items-center space-x-2">
          {isChecking ? (
            <Loader className="w-4 h-4 animate-spin text-cream" />
          ) : status.isConfigured ? (
            <CheckCircle className="w-4 h-4 text-green-500" />
          ) : (
            <AlertTriangle className="w-4 h-4 text-red-500" />
          )}
          <span className={status.isConfigured ? "text-green-500" : "text-red-500"}>
            {status.isConfigured ? 'Configured' : 'Not Configured'}
          </span>
        </div>

        {status.error && (
          <div className="bg-surface-secondary rounded-lg p-3">
            <p className="text-sm text-red-400">{status.error}</p>
          </div>
        )}

        {status.config.projectId && (
          <div className="bg-surface-secondary rounded-lg p-3 space-y-2">
            <div className="flex items-center justify-between">
              <span className="text-sm text-text-secondary">Project ID:</span>
              <span className="text-sm text-text-primary font-mono">{status.config.projectId}</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm text-text-secondary">Location:</span>
              <span className="text-sm text-text-primary font-mono">{status.config.location}</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm text-text-secondary">Model:</span>
              <span className="text-sm text-text-primary font-mono">{status.config.modelName}</span>
            </div>
          </div>
        )}

        {status.lastChecked && (
          <p className="text-sm text-text-secondary">
            Last checked: {status.lastChecked.toLocaleTimeString()}
          </p>
        )}

        <div className="space-y-2">
          <p className="text-sm text-text-secondary">
            Required Environment Variables:
          </p>
          <ul className="list-disc list-inside text-sm text-text-secondary space-y-1 ml-2">
            <li><code className="bg-surface px-2 py-0.5 rounded">VITE_GOOGLE_CLOUD_PROJECT</code></li>
            <li><code className="bg-surface px-2 py-0.5 rounded">VITE_GOOGLE_CLOUD_LOCATION</code></li>
            <li><code className="bg-surface px-2 py-0.5 rounded">VITE_GOOGLE_CLOUD_MODEL</code></li>
          </ul>
        </div>
      </div>
    </div>
  );
}