import { loggingService } from '../../services/logging';

const PUBLIC_DOMAIN = 'https://outnow.mov';

export function getPublicWorkbookUrl(workbookId: string): string {
  return `${PUBLIC_DOMAIN}/workbook/${workbookId}/public`;
}

export async function copyWorkbookUrl(workbookId: string): Promise<void> {
  try {
    const shareUrl = getPublicWorkbookUrl(workbookId);
    await navigator.clipboard.writeText(shareUrl);
    
    loggingService.addLog({
      type: 'success',
      message: 'Share URL copied to clipboard',
      data: { workbookId, shareUrl }
    });
  } catch (error) {
    loggingService.addLog({
      type: 'error',
      message: 'Failed to copy share URL',
      data: { error, workbookId }
    });
    throw error;
  }
}