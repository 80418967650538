import { useEffect } from 'react';
import { useResponsiveStore } from '../lib/responsive';

export function useResponsive() {
  const { isMobile, isTablet, isDesktop, setDeviceType } = useResponsiveStore();

  useEffect(() => {
    // Set initial device type
    setDeviceType(window.innerWidth);

    // Add resize listener
    const handleResize = () => {
      setDeviceType(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [setDeviceType]);

  return { isMobile, isTablet, isDesktop };
}