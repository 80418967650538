import { supabase } from '../../lib/supabase';
import { geminiService } from '../ai';
import { loggingService } from '../logging';

interface AnalysisProgress {
  totalVideos: number;
  processedVideos: number;
  currentVideo: string | null;
  isAnalyzing: boolean;
  lastAnalysis: {
    content: string | null;
    mechanic: string | null;
    timestamp: Date | null;
  };
}

class BulkVideoAnalyzer {
  private static instance: BulkVideoAnalyzer;
  private progress: AnalysisProgress = {
    totalVideos: 0,
    processedVideos: 0,
    currentVideo: null,
    isAnalyzing: false,
    lastAnalysis: {
      content: null,
      mechanic: null,
      timestamp: null
    }
  };
  private subscribers: ((progress: AnalysisProgress) => void)[] = [];

  private constructor() {
    // Load last analysis from localStorage if available
    const savedAnalysis = localStorage.getItem('lastVideoAnalysis');
    if (savedAnalysis) {
      const parsed = JSON.parse(savedAnalysis);
      this.progress.lastAnalysis = {
        content: parsed.content,
        mechanic: parsed.mechanic,
        timestamp: new Date(parsed.timestamp)
      };
    }
  }

  static getInstance(): BulkVideoAnalyzer {
    if (!BulkVideoAnalyzer.instance) {
      BulkVideoAnalyzer.instance = new BulkVideoAnalyzer();
    }
    return BulkVideoAnalyzer.instance;
  }

  subscribe(callback: (progress: AnalysisProgress) => void) {
    this.subscribers.push(callback);
    callback({ ...this.progress });
    return () => {
      this.subscribers = this.subscribers.filter(cb => cb !== callback);
    };
  }

  private updateProgress(update: Partial<AnalysisProgress>) {
    this.progress = { ...this.progress, ...update };
    this.subscribers.forEach(cb => cb({ ...this.progress }));
  }

  private saveLastAnalysis(content: string, mechanic: string) {
    const lastAnalysis = {
      content,
      mechanic,
      timestamp: new Date()
    };
    this.updateProgress({ lastAnalysis });
    localStorage.setItem('lastVideoAnalysis', JSON.stringify(lastAnalysis));
  }

  async analyzeAllVideos() {
    if (this.progress.isAnalyzing) {
      throw new Error('Analysis already in progress');
    }

    try {
      this.updateProgress({ isAnalyzing: true });

      // Get all videos that need analysis
      const { data: videos, error } = await supabase
        .from('videos')
        .select('*')
        .is('content', null)
        .order('created_at', { ascending: false });

      if (error) throw error;

      this.updateProgress({
        totalVideos: videos.length,
        processedVideos: 0,
        currentVideo: null
      });

      loggingService.addLog({
        type: 'info',
        message: `Starting bulk analysis of ${videos.length} videos`
      });

      // Process videos in sequence
      for (const video of videos) {
        if (!video.drive_url) continue;

        this.updateProgress({ currentVideo: video.video_id });

        try {
          const analysis = await geminiService.analyzeVideo({
            fileUrl: video.tiktok_file_url,
            driveUrl: video.drive_url,
            description: video.description || '',
            stats: {
              viewCount: video.view_count || 0,
              likeCount: video.like_count || 0,
              shareCount: video.share_count || 0,
              commentCount: video.comment_count || 0
            }
          });

          // Save last successful analysis
          this.saveLastAnalysis(analysis.content, analysis.mechanic);

          // Update video with analysis
          const { error: updateError } = await supabase
            .from('videos')
            .update({
              content: analysis.content,
              mechanic: analysis.mechanic,
              updated_at: new Date().toISOString()
            })
            .eq('video_id', video.video_id);

          if (updateError) throw updateError;

          this.updateProgress({
            processedVideos: this.progress.processedVideos + 1
          });

          loggingService.addLog({
            type: 'success',
            message: `Analyzed video ${video.video_id}`,
            data: { videoId: video.video_id }
          });
        } catch (error) {
          loggingService.addLog({
            type: 'error',
            message: `Failed to analyze video ${video.video_id}`,
            data: { error, videoId: video.video_id }
          });
        }

        // Add small delay between videos
        await new Promise(resolve => setTimeout(resolve, 1000));
      }

      loggingService.addLog({
        type: 'success',
        message: 'Bulk analysis completed',
        data: {
          totalVideos: videos.length,
          processedVideos: this.progress.processedVideos
        }
      });
    } catch (error) {
      loggingService.addLog({
        type: 'error',
        message: 'Bulk analysis failed',
        data: error
      });
      throw error;
    } finally {
      this.updateProgress({
        isAnalyzing: false,
        currentVideo: null
      });
    }
  }
}

export const bulkVideoAnalyzer = BulkVideoAnalyzer.getInstance();