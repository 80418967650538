import { VideoSource } from './types';
import { VideoValidationError } from './errors';
import { ERROR_MESSAGES } from './constants';
import { loggingService } from '../../services/logging';
import { supabase } from '../supabase';

export async function getVideoUrls(videoId: string): Promise<VideoSource> {
  try {
    // First try to find video by video_id
    const { data: video, error } = await supabase
      .from('videos')
      .select('tiktok_file_url, drive_url, video_id')
      .eq('video_id', videoId)
      .single();

    if (error || !video) {
      throw new VideoValidationError(ERROR_MESSAGES.NO_URL_AVAILABLE);
    }

    loggingService.addLog({
      type: 'success',
      message: 'Retrieved video URLs from database',
      data: {
        videoId: video.video_id,
        hasFileUrl: !!video.tiktok_file_url,
        hasDriveUrl: !!video.drive_url
      }
    });

    return {
      fileUrl: video.tiktok_file_url,
      driveUrl: video.drive_url,
      videoUrl: null
    };
  } catch (error) {
    loggingService.addLog({
      type: 'error',
      message: 'Failed to get video URLs',
      data: { error, videoId }
    });
    throw error;
  }
}

export function formatDropboxUrl(url: string): string {
  if (!url) return '';
  
  try {
    // Convert www.dropbox.com to dl.dropboxusercontent.com
    let formattedUrl = url.replace('www.dropbox.com', 'dl.dropboxusercontent.com');

    // Remove any existing dl parameter
    formattedUrl = formattedUrl.replace(/[?&]dl=\d/g, '');

    // Add raw=1 parameter
    if (formattedUrl.includes('?')) {
      if (!formattedUrl.includes('raw=1')) {
        formattedUrl += '&raw=1';
      }
    } else {
      formattedUrl += '?raw=1';
    }

    loggingService.addLog({
      type: 'info',
      message: 'Formatted Dropbox URL',
      data: { 
        originalUrl: url, 
        formattedUrl
      }
    });

    return formattedUrl;
  } catch (error) {
    loggingService.addLog({
      type: 'error',
      message: 'Failed to format Dropbox URL',
      data: { error, url }
    });
    throw new VideoValidationError(ERROR_MESSAGES.INVALID_URL, { url, error });
  }
}

export function getBestVideoUrl(sources: VideoSource, forGemini: boolean = false): string {
  try {
    const { driveUrl, fileUrl } = sources;

    loggingService.addLog({
      type: 'info',
      message: 'Getting best video URL',
      data: {
        driveUrl: driveUrl || 'not available',
        fileUrl: fileUrl || 'not available',
        forGemini
      }
    });

    if (forGemini) {
      // For Gemini analysis, prioritize Dropbox URL
      if (!driveUrl) {
        throw new VideoValidationError(ERROR_MESSAGES.NO_DROPBOX_URL);
      }
      
      const formattedUrl = formatDropboxUrl(driveUrl);
      loggingService.addLog({
        type: 'success',
        message: 'Using Dropbox URL for Gemini analysis',
        data: { formattedUrl }
      });
      return formattedUrl;
    }

    // For video player, use TikTok file URL
    if (!fileUrl) {
      throw new VideoValidationError(ERROR_MESSAGES.NO_URL_AVAILABLE);
    }

    loggingService.addLog({
      type: 'success',
      message: 'Using TikTok file URL for playback',
      data: { url: fileUrl }
    });
    return fileUrl;
  } catch (error) {
    loggingService.addLog({
      type: 'error',
      message: 'Failed to get video URL',
      data: { error, sources, forGemini }
    });
    throw error;
  }
}