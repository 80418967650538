import { Music2, ExternalLink, Play, Heart } from 'lucide-react';
import { Hot50Entry } from '../../types/hot50';
import { VideoBadge } from '../VideoBadge';
import { formatNumber } from '../../lib/utils';

interface TrackCardProps {
  entry: Hot50Entry;
  onTrackClick: (rank: number) => void;
  onTikTokClick: (e: React.MouseEvent<HTMLButtonElement>, url: string) => void;
}

export function TrackCard({ entry, onTrackClick, onTikTokClick }: TrackCardProps) {
  // Calculate total stats from all videos
  const totalStats = entry.videos.reduce((acc, video) => ({
    views: acc.views + (video.view_count || 0),
    likes: acc.likes + (video.like_count || 0)
  }), { views: 0, likes: 0 });

  return (
    <div
      onClick={() => onTrackClick(entry.rank)}
      className="block bg-surface border border-border rounded-lg p-4 hover:shadow-lg transition-all cursor-pointer"
    >
      <div className="flex items-center space-x-4">
        <div className="flex-shrink-0 w-12 h-12 rounded-lg overflow-hidden">
          {entry.tiktok_artwork_url ? (
            <img
              src={entry.tiktok_artwork_url}
              alt={`${entry.track_name} artwork`}
              className="w-full h-full object-cover"
            />
          ) : (
            <div className="w-full h-full bg-surface-secondary flex items-center justify-center">
              <Music2 className="w-6 h-6 text-text-secondary" />
            </div>
          )}
        </div>
        
        <div className="flex-1 min-w-0">
          <div className="flex items-center space-x-3">
            <h3 className="text-lg font-medium text-text-primary truncate">
              {entry.track_name}
            </h3>
            {entry.videos && entry.videos.length > 0 && (
              <VideoBadge count={entry.videos.length} />
            )}
          </div>
          <p className="text-text-secondary truncate">{entry.artist_name}</p>
        </div>

        <div className="flex items-center space-x-6">
          <div className="text-text-secondary text-sm space-x-4">
            <span className="inline-flex items-center space-x-1">
              <Play className="w-4 h-4" />
              <span>{formatNumber(totalStats.views)}</span>
            </span>
            <span className="inline-flex items-center space-x-1">
              <Heart className="w-4 h-4" />
              <span>{formatNumber(totalStats.likes)}</span>
            </span>
          </div>

          {entry.tiktok_sound_url && (
            <button
              onClick={(e) => onTikTokClick(e, entry.tiktok_sound_url!)}
              className="p-2 hover:bg-surface-secondary rounded-lg transition-colors"
            >
              <ExternalLink className="w-4 h-4 text-text-secondary" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}