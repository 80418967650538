import { Search, Plus } from 'lucide-react';
import { ThemeToggle } from '../ThemeToggle';

export function MobileNav() {
  return (
    <nav className="fixed top-0 right-0 left-0 bg-background border-b border-border z-30 px-4 py-2">
      <div className="flex items-center justify-between">
        {/* Search bar with left padding for menu button */}
        <div className="relative flex-1 ml-12">
          <input
            type="text"
            placeholder="Search..."
            className="w-full px-4 py-1.5 bg-surface rounded-lg text-sm text-text-primary placeholder:text-text-secondary focus:outline-none focus:ring-1 focus:ring-primary"
          />
          <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-text-secondary" />
        </div>

        {/* Actions */}
        <div className="flex items-center ml-3">
          <ThemeToggle />
          <button className="ml-2 p-2 bg-cream text-background rounded-lg" aria-label="New project">
            <Plus className="w-4 h-4" />
          </button>
        </div>
      </div>
    </nav>
  );
}