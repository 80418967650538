import { Share2, Check } from 'lucide-react';
import { useState } from 'react';
import { cn } from '../../lib/utils';
import { DeleteButton } from './DeleteButton';
import { SaveTemplateButton } from './SaveTemplateButton';
import { copyWorkbookUrl } from '../../lib/workbook/sharing';
import { useNotificationStore } from '../../store/notifications';

interface WorkbookActionsProps {
  workbookId: string;
  isPublic?: boolean;
  className?: string;
}

export function WorkbookActions({ workbookId, isPublic, className }: WorkbookActionsProps) {
  const [copied, setCopied] = useState(false);
  const addNotification = useNotificationStore(state => state.addNotification);

  // Don't render any actions in public view
  if (isPublic) {
    return null;
  }

  const handleShare = async () => {
    try {
      await copyWorkbookUrl(workbookId);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
      
      addNotification({
        type: 'info',
        message: 'Public URL copied to clipboard'
      });
    } catch (error) {
      addNotification({
        type: 'error',
        message: 'Failed to copy URL to clipboard'
      });
    }
  };

  return (
    <div className={cn("flex items-center space-x-2", className)}>
      <SaveTemplateButton workbookId={workbookId} />
      <DeleteButton workbookId={workbookId} />
      
      <button
        onClick={handleShare}
        className="flex items-center space-x-2 px-3 py-1.5 bg-surface rounded-lg text-text-primary hover:bg-surface-secondary transition-colors"
      >
        {copied ? (
          <>
            <Check className="w-4 h-4 text-green-500" />
            <span className="text-sm">Copied!</span>
          </>
        ) : (
          <>
            <Share2 className="w-4 h-4" />
            <span className="text-sm">Share</span>
          </>
        )}
      </button>
    </div>
  );
}