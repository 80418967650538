import { useState, useEffect } from 'react';
import { Brain, Loader, Clock } from 'lucide-react';
import { bulkVideoAnalyzer } from '../../services/video-analysis/bulk-analyzer';
import { loggingService } from '../../services/logging';
import { GeminiResponse } from '../ai/GeminiResponse';
import { formatDistanceToNow } from 'date-fns';

export function BulkAnalysis() {
  const [progress, setProgress] = useState({
    totalVideos: 0,
    processedVideos: 0,
    currentVideo: null as string | null,
    isAnalyzing: false,
    lastAnalysis: {
      content: null as string | null,
      mechanic: null as string | null,
      timestamp: null as Date | null
    }
  });

  useEffect(() => {
    const unsubscribe = bulkVideoAnalyzer.subscribe(setProgress);
    return unsubscribe;
  }, []);

  const handleAnalyze = async () => {
    try {
      await bulkVideoAnalyzer.analyzeAllVideos();
    } catch (error) {
      loggingService.addLog({
        type: 'error',
        message: 'Failed to start bulk analysis',
        data: error
      });
    }
  };

  return (
    <div className="bg-surface border border-border rounded-lg p-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center space-x-3">
          <Brain className="w-5 h-5 text-cream" />
          <h3 className="text-lg font-medium text-text-primary">Bulk Video Analysis</h3>
        </div>
      </div>

      <div className="space-y-6">
        {progress.isAnalyzing ? (
          <div className="space-y-4">
            <div className="flex items-center justify-between text-sm text-text-secondary">
              <span>Analyzing videos...</span>
              <span>{progress.processedVideos} / {progress.totalVideos}</span>
            </div>
            <div className="w-full bg-surface-secondary rounded-full h-2">
              <div 
                className="bg-cream h-full rounded-full transition-all duration-300"
                style={{ 
                  width: `${(progress.processedVideos / progress.totalVideos) * 100}%` 
                }}
              />
            </div>
            {progress.currentVideo && (
              <p className="text-sm text-text-secondary">
                Currently analyzing: {progress.currentVideo}
              </p>
            )}
          </div>
        ) : (
          <button
            onClick={handleAnalyze}
            className="w-full px-4 py-2 bg-cream text-stone-dark rounded-lg hover:bg-cream-dark transition-colors flex items-center justify-center space-x-2"
          >
            <Brain className="w-4 h-4" />
            <span>Analyze All Videos</span>
          </button>
        )}

        {/* Show last analysis if available */}
        {progress.lastAnalysis.content && progress.lastAnalysis.mechanic && (
          <div className="space-y-4">
            <div className="flex items-center space-x-2 text-sm text-text-secondary">
              <Clock className="w-4 h-4" />
              <span>Last analysis: {
                progress.lastAnalysis.timestamp 
                  ? formatDistanceToNow(progress.lastAnalysis.timestamp, { addSuffix: true })
                  : 'Unknown'
              }</span>
            </div>
            <GeminiResponse
              content={progress.lastAnalysis.content}
              mechanic={progress.lastAnalysis.mechanic}
              className="bg-surface-secondary rounded-lg p-4"
            />
          </div>
        )}
      </div>
    </div>
  );
}