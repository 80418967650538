import { useState, useEffect } from 'react';
import { Descendant } from 'slate';
import { fetchWorkbook } from '../database';
import { parseWorkbookContent } from '../content';
import { loggingService } from '../../../services/logging';

const defaultValue = [{ type: 'paragraph', children: [{ text: '' }] }];

interface UseWorkbookLoaderResult {
  title: string;
  content: Descendant[];
  releaseDate: string | null;
  isLoading: boolean;
  error: string | null;
}

export function useWorkbookLoader(id: string | undefined): UseWorkbookLoaderResult {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState<Descendant[]>(defaultValue);
  const [releaseDate, setReleaseDate] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadWorkbook = async () => {
      if (!id) {
        setError('No workbook ID provided');
        setIsLoading(false);
        return;
      }

      try {
        const workbook = await fetchWorkbook(id);
        setTitle(workbook.title);
        setReleaseDate(workbook.release_date);
        
        try {
          const parsedContent = parseWorkbookContent(workbook.content);
          setContent(Array.isArray(parsedContent) && parsedContent.length > 0 
            ? parsedContent 
            : defaultValue
          );
        } catch (parseError) {
          loggingService.addLog({
            type: 'error',
            message: 'Failed to parse workbook content',
            data: { parseError, id }
          });
          setContent(defaultValue);
        }
      } catch (error) {
        setError(error instanceof Error ? error.message : 'Failed to load workbook');
        loggingService.addLog({
          type: 'error',
          message: 'Failed to load workbook',
          data: { error, id }
        });
      } finally {
        setIsLoading(false);
      }
    };

    loadWorkbook();
  }, [id]);

  return { title, content, releaseDate, isLoading, error };
}