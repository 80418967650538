import { parse, isValid, isFriday, setDay } from 'date-fns';

export function findSecondFriday(releaseDate: string | null): Date | null {
  if (!releaseDate) return null;

  try {
    // Parse date in DD.MM.YY format
    const date = parse(releaseDate, 'dd.MM.yy', new Date());
    if (!isValid(date)) return null;

    // Always treat input date as the release date (row 12)
    return isFriday(date) ? date : setDay(date, 5);
  } catch {
    return null;
  }
}