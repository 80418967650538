import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Video } from '../../types/hot50';
import { VideoPlayer } from './VideoPlayer';
import { VideoInfoCard } from './VideoInfoCard';
import { SortSelect, SortOption } from './SortSelect';

interface VideoGridProps {
  videos: Video[];
  sortBy?: SortOption;
  onSortChange?: (value: SortOption) => void;
}

export function VideoGrid({ videos, sortBy = 'shares', onSortChange }: VideoGridProps) {
  const navigate = useNavigate();

  // Filter videos that have all required fields
  const validVideos = useMemo(() => 
    videos.filter(video => 
      video.view_count != null && 
      video.tiktok_thumbnail_url != null && 
      video.tiktok_file_url != null
    ),
    [videos]
  );

  // Sort videos based on selected option
  const sortedVideos = useMemo(() => {
    return [...validVideos].sort((a, b) => {
      const getValue = (video: Video) => {
        switch (sortBy) {
          case 'views':
            return video.view_count || 0;
          case 'likes':
            return video.like_count || 0;
          case 'shares':
            return video.share_count || 0;
          case 'comments':
            return video.comment_count || 0;
          default:
            return 0;
        }
      };
      return getValue(b) - getValue(a);
    });
  }, [validVideos, sortBy]);

  if (validVideos.length === 0) {
    return (
      <div className="flex items-center justify-center h-64 bg-surface rounded-lg">
        <p className="text-text-secondary">No videos available</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {onSortChange && (
        <div className="flex justify-end">
          <SortSelect 
            value={sortBy} 
            onChange={onSortChange}
          />
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {sortedVideos.map((video) => (
          <div key={video.video_id}>
            <div className="aspect-[9/16] bg-surface rounded-t-lg overflow-hidden">
              <VideoPlayer
                src={video.tiktok_file_url}
                poster={video.tiktok_thumbnail_url}
                stats={{
                  viewCount: video.view_count,
                  likeCount: video.like_count,
                  shareCount: video.share_count,
                  commentCount: video.comment_count
                }}
                onClick={() => navigate(`/video/${video.video_id}`)}
              />
            </div>
            <VideoInfoCard
              videoUrl={video.tiktok_video_url || ''}
              stats={{
                viewCount: video.view_count,
                likeCount: video.like_count,
                shareCount: video.share_count,
                commentCount: video.comment_count
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}