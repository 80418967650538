import { useState } from 'react';
import { Send } from 'lucide-react';

interface ThemeInputProps {
  onSubmit: (theme: string) => void;
  disabled?: boolean;
}

export function ThemeInput({ onSubmit, disabled }: ThemeInputProps) {
  const [theme, setTheme] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (theme.trim()) {
      onSubmit(theme.trim());
      setTheme('');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-6">
      <div className="flex items-center space-x-3">
        <input
          type="text"
          value={theme}
          onChange={(e) => setTheme(e.target.value)}
          placeholder="Gib ein Thema ein (z.B. Fitness, Reisen, Kochen...)"
          className="flex-1 px-4 py-2 bg-surface-secondary rounded-lg text-text-primary placeholder:text-text-secondary focus:outline-none focus:ring-1 focus:ring-cream"
          disabled={disabled}
        />
        <button
          type="submit"
          disabled={disabled || !theme.trim()}
          className="px-4 py-2 bg-cream text-stone-dark rounded-lg hover:bg-cream-dark transition-colors disabled:opacity-50 flex items-center space-x-2"
        >
          <Send className="w-4 h-4" />
          <span>Analysieren</span>
        </button>
      </div>
    </form>
  );
}