import { useEffect } from 'react';
import { useNotificationStore } from '../../store/notifications';
import { Notification } from './Notification';

const NOTIFICATION_TIMEOUT = 5000;

export function NotificationContainer() {
  const { notifications, removeNotification } = useNotificationStore();

  useEffect(() => {
    const timeouts: NodeJS.Timeout[] = [];

    notifications.forEach(notification => {
      if (!notification.isPersistent) {
        const timeout = setTimeout(() => {
          removeNotification(notification.id);
        }, NOTIFICATION_TIMEOUT);
        timeouts.push(timeout);
      }
    });

    // Cleanup timeouts on unmount or when notifications change
    return () => {
      timeouts.forEach(timeout => clearTimeout(timeout));
    };
  }, [notifications, removeNotification]);

  return (
    <div className="fixed bottom-4 right-4 z-50 space-y-2">
      {notifications.map(notification => (
        <Notification 
          key={notification.id} 
          {...notification} 
          onClose={() => removeNotification(notification.id)}
        />
      ))}
    </div>
  );
}