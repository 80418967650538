import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { MainLayout, PublicLayout } from './layouts';
import { MobileLayout } from './components/mobile';
import { Hot50Page } from './pages/Hot50Page';
import { TrackDetailsPage } from './pages/TrackDetailsPage';
import { VideoDetailPage } from './pages/VideoDetailPage';
import { SettingsPage } from './pages/SettingsPage';
import { TermsPage } from './pages/TermsPage';
import { PrivacyPage } from './pages/PrivacyPage';
import { WorkbookPage } from './pages/WorkbookPage';
import { PublicWorkbookPage } from './pages/PublicWorkbookPage';
import { useThemeStore } from './lib/theme';
import { NotificationContainer } from './components/ui/NotificationContainer';
import { useResponsive } from './hooks/useResponsive';

export default function App() {
  const isDark = useThemeStore((state) => state.isDark);
  const { isMobile } = useResponsive();
  const Layout = isMobile ? MobileLayout : MainLayout;

  return (
    <div className={isDark ? 'dark' : ''}>
      <Router>
        <Routes>
          {/* Public routes */}
          <Route element={<PublicLayout />}>
            <Route path="/w/:hash/:id" element={<PublicWorkbookPage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
          </Route>

          {/* Private routes */}
          <Route element={<Layout />}>
            <Route path="/" element={<Hot50Page />} />
            <Route path="/track/:position" element={<TrackDetailsPage />} />
            <Route path="/video/:videoId" element={<VideoDetailPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/workbook/:id" element={<WorkbookPage />} />
          </Route>

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
      <NotificationContainer />
      <div id="datepicker-portal" />
    </div>
  );
}