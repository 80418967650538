import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Music2 } from 'lucide-react';
import { useHot50Store } from '../store/hot50';
import { TrackList } from '../components/hot50/TrackList';
import { UpdateHot50Button } from '../components/hot50/UpdateHot50Button';
import { useResponsive } from '../hooks/useResponsive';

export function Hot50Page() {
  const { entries, isLoading, error, fetchEntries } = useHot50Store();
  const navigate = useNavigate();
  const { isMobile } = useResponsive();

  useEffect(() => {
    fetchEntries();
  }, [fetchEntries]);

  const handleTrackClick = (rank: number) => {
    navigate(`/track/${rank}`);
  };

  const handleTikTokClick = (e: React.MouseEvent<HTMLButtonElement>, url: string) => {
    e.stopPropagation();
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  if (isLoading) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8">
        <p className="text-text-primary">Loading Hot50 entries...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="text-red-500 mb-4">Error: {error}</div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center space-x-3">
          <Music2 className="w-6 h-6 text-cream" />
          <h1 className="text-2xl font-semibold text-text-primary">TikTok Hot50 DE</h1>
        </div>
        {!isMobile && <UpdateHot50Button />}
      </div>

      <TrackList
        entries={entries}
        onTrackClick={handleTrackClick}
        onTikTokClick={handleTikTokClick}
        isMobile={isMobile}
      />
    </div>
  );
}