import { Descendant } from 'slate';
import { WorkbookContent } from './types';

export function parseWorkbookContent(content: string): Descendant[] {
  try {
    const parsed = JSON.parse(content);
    return Array.isArray(parsed) ? parsed : [];
  } catch {
    return [];
  }
}

export function stringifyWorkbookContent(content: Descendant[]): string {
  return JSON.stringify(content);
}

export function findReleaseDate(content: Descendant[]): string | null {
  try {
    for (const node of content) {
      if (
        'type' in node && 
        node.type === 'h2' && 
        node.children?.[0]?.text === '📆 Release Date' &&
        node.children?.[1]?.releaseDate
      ) {
        return node.children[1].text;
      }
    }
    return null;
  } catch {
    return null;
  }
}

export function updateWorkbookReleaseDate(content: Descendant[], releaseDate: string): Descendant[] {
  return content.map(node => {
    if (
      'type' in node && 
      node.type === 'h2' && 
      node.children?.[0]?.text === '📆 Release Date'
    ) {
      return {
        ...node,
        children: [
          node.children[0],
          { 
            text: releaseDate,
            releaseDate: true 
          }
        ]
      };
    }
    return node;
  });
}

export function validateReleaseDate(date: string): boolean {
  const dateRegex = /^\d{2}\/\d{2}\/\d{2}$/;
  return dateRegex.test(date);
}