import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { createFromTemplate } from '../database';
import { fetchWorkbook } from '../../database';
import { loggingService } from '../../../../services/logging';
import { useNotificationStore } from '../../../../store/notifications';
import { useWorkbookStore } from '../../../../store/workbooks';

export function useTemplateCreation() {
  const [isCreating, setIsCreating] = useState(false);
  const navigate = useNavigate();
  const addNotification = useNotificationStore(state => state.addNotification);
  const addWorkbook = useWorkbookStore(state => state.addWorkbook);

  const createWorkbook = useCallback(async (templateId: string = 'artist-workbook') => {
    if (isCreating) return;
    
    setIsCreating(true);
    try {
      const workbookId = await createFromTemplate(templateId);
      
      // Fetch the created workbook to get full details
      const workbook = await fetchWorkbook(workbookId);
      
      // Add to store
      addWorkbook(workbook);
      
      addNotification({
        type: 'info',
        message: 'Created new workbook from template'
      });

      // Short delay to ensure database write is complete
      await new Promise(resolve => setTimeout(resolve, 100));
      
      navigate(`/workbook/${workbookId}`);
      return workbookId;
    } catch (error) {
      loggingService.addLog({
        type: 'error',
        message: 'Failed to create workbook from template',
        data: { error, templateId }
      });
      
      addNotification({
        type: 'error',
        message: 'Failed to create workbook',
        data: error instanceof Error ? error.message : 'Unknown error'
      });
      
      throw error;
    } finally {
      setIsCreating(false);
    }
  }, [navigate, addNotification, addWorkbook, isCreating]);

  return {
    isCreating,
    createWorkbook
  };
}