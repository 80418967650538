import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { geminiService } from '../services/ai';
import { loggingService } from '../services/logging';

interface VideoDetails {
  video_id: string;
  tiktok_file_url: string | null;
  drive_url: string | null;
  description: string | null;
  view_count: number | null;
  like_count: number | null;
  share_count: number | null;
  comment_count: number | null;
  content: string | null;
  mechanic: string | null;
}

interface LogEntry {
  timestamp: Date;
  type: 'info' | 'success' | 'error';
  message: string;
  data?: any;
}

export function useVideoAnalysis(video: VideoDetails | null) {
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [contentAnalysis, setContentAnalysis] = useState<string>(video?.content || '');
  const [mechanic, setMechanic] = useState<string>(video?.mechanic || '');
  const [analysisLogs, setAnalysisLogs] = useState<LogEntry[]>([]);

  // Auto-start analysis when video is loaded and needs analysis
  useEffect(() => {
    if (video && !video.content && video.drive_url && !isAnalyzing) {
      handleAnalyze();
    }
  }, [video]);

  const handleAnalyze = async (theme?: string) => {
    if (!video || isAnalyzing || !video.drive_url) return;

    setIsAnalyzing(true);
    setContentAnalysis('');
    setMechanic('');
    setAnalysisLogs([]);

    try {
      const result = await geminiService.analyzeVideo({
        fileUrl: video.tiktok_file_url,
        driveUrl: video.drive_url,
        description: video.description || '',
        stats: {
          viewCount: video.view_count || 0,
          likeCount: video.like_count || 0,
          shareCount: video.share_count || 0,
          commentCount: video.comment_count || 0
        },
        customTheme: theme
      });

      setContentAnalysis(result.content);
      setMechanic(result.mechanic);

      // Only save to database if no custom theme was used
      if (!theme) {
        const { error: updateError } = await supabase
          .from('videos')
          .update({
            content: result.content,
            mechanic: result.mechanic,
            updated_at: new Date().toISOString()
          })
          .eq('video_id', video.video_id);

        if (updateError) throw updateError;
      }

      loggingService.addLog({
        type: 'success',
        message: 'Analysis complete',
        data: {
          contentLength: result.content.length,
          mechanicLength: result.mechanic.length,
          customTheme: theme
        }
      });
    } catch (error) {
      loggingService.addLog({
        type: 'error',
        message: 'Failed to analyze video',
        data: { error, videoId: video.video_id }
      });
    } finally {
      setIsAnalyzing(false);
    }
  };

  return {
    isAnalyzing,
    contentAnalysis,
    mechanic,
    analysisLogs,
    handleAnalyze
  };
}