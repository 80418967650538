import { create } from 'zustand';
import { createNotification } from '../lib/utils/notification';
import type { Notification } from '../lib/utils/notification';

interface NotificationStore {
  notifications: (Notification & { isPersistent?: boolean })[];
  addNotification: (notification: Omit<Notification, 'id'> & { isPersistent?: boolean }) => void;
  removeNotification: (id: string) => void;
  clearNotifications: () => void;
}

export const useNotificationStore = create<NotificationStore>((set) => ({
  notifications: [],
  addNotification: (notification) => set((state) => ({
    notifications: [
      ...state.notifications,
      { ...createNotification(notification.type, notification.message, notification.data), isPersistent: notification.isPersistent }
    ]
  })),
  removeNotification: (id) => set((state) => ({
    notifications: state.notifications.filter(n => n.id !== id)
  })),
  clearNotifications: () => set({ notifications: [] })
}));