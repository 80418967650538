import { verifyPublicHash } from '../security/hash';
import { supabase } from '../../supabase';

export async function verifyWorkbookAccess(workbookId: string, hash: string): Promise<boolean> {
  try {
    // Verify hash first
    const isValidHash = await verifyPublicHash(workbookId, hash);
    if (!isValidHash) {
      return false;
    }

    // Check if workbook exists and is public
    const { data, error } = await supabase
      .from('workbooks')
      .select('is_public')
      .eq('id', workbookId)
      .single();

    if (error || !data?.is_public) {
      return false;
    }

    return true;
  } catch {
    return false;
  }
}