import { ArrowDownWideNarrow } from 'lucide-react';
import { cn } from '../../lib/utils';

export type SortOption = 'shares' | 'views' | 'likes' | 'comments';

interface SortSelectProps {
  value: SortOption;
  onChange: (value: SortOption) => void;
  className?: string;
}

export function SortSelect({ value, onChange, className }: SortSelectProps) {
  return (
    <div className={cn("flex items-center space-x-2", className)}>
      <ArrowDownWideNarrow className="w-4 h-4 text-text-secondary" />
      <select
        value={value}
        onChange={(e) => onChange(e.target.value as SortOption)}
        className="bg-surface text-text-primary text-sm rounded-lg px-3 py-1.5 border border-border focus:outline-none focus:ring-1 focus:ring-cream"
      >
        <option value="shares">Most Shared</option>
        <option value="views">Most Viewed</option>
        <option value="likes">Most Liked</option>
        <option value="comments">Most Commented</option>
      </select>
    </div>
  );
}