import { supabase } from '../../lib/supabase';
import { loggingService } from '../logging';
import { spreadsheetService } from './spreadsheet';

export class Hot50ImportService {
  async archiveCurrentHot50(): Promise<void> {
    try {
      const { error } = await supabase.rpc('update_hot50');
      
      if (error) {
        throw error;
      }

      loggingService.addLog({
        type: 'success',
        message: 'Successfully archived current Hot50 data'
      });
    } catch (error) {
      loggingService.addLog({
        type: 'error',
        message: 'Failed to archive Hot50 data',
        data: error
      });
      throw error;
    }
  }

  async importNewHot50(): Promise<void> {
    try {
      // Fetch new data from spreadsheet
      const entries = await spreadsheetService.fetchHot50Data();

      // Insert new entries
      const { error: insertError } = await supabase
        .from('hot50')
        .insert(entries.map(entry => ({
          rank: entry.rank,
          track_name: entry.track_name,
          artist_name: entry.artist_name,
          tiktok_sound_url: entry.tiktok_sound_url,
          tiktok_artwork_url: entry.tiktok_artwork_url
        })));

      if (insertError) throw insertError;

      loggingService.addLog({
        type: 'success',
        message: 'Successfully imported new Hot50 data',
        data: { entriesCount: entries.length }
      });
    } catch (error) {
      loggingService.addLog({
        type: 'error',
        message: 'Failed to import new Hot50 data',
        data: error
      });
      throw error;
    }
  }

  async updateHot50(): Promise<void> {
    try {
      // Step 1: Archive current data
      await this.archiveCurrentHot50();

      // Step 2: Import new data
      await this.importNewHot50();

      loggingService.addLog({
        type: 'success',
        message: 'Hot50 update completed successfully'
      });
    } catch (error) {
      loggingService.addLog({
        type: 'error',
        message: 'Failed to update Hot50',
        data: error
      });
      throw error;
    }
  }
}

export const hot50ImportService = new Hot50ImportService();