import { useEffect, useRef } from 'react';
import { cn } from '../../lib/utils';

interface VideoThumbnailProps {
  src: string | null;
  videoUrl: string | null;
  isHidden: boolean;
}

export function VideoThumbnail({ src, videoUrl, isHidden }: VideoThumbnailProps) {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    // Load first frame when video URL changes
    if (videoRef.current && videoUrl) {
      videoRef.current.load();
      videoRef.current.currentTime = 0;
    }
  }, [videoUrl]);

  if (!src && !videoUrl) {
    return (
      <div className="absolute inset-0 bg-surface-secondary flex items-center justify-center">
        <p className="text-text-secondary text-sm">No preview</p>
      </div>
    );
  }

  return (
    <>
      {videoUrl && (
        <video
          ref={videoRef}
          className={cn(
            "absolute inset-0 w-full h-full object-cover transition-opacity duration-300",
            isHidden ? "opacity-0" : "opacity-100"
          )}
          src={videoUrl}
          preload="metadata"
          playsInline
          muted
          poster={src || undefined}
        />
      )}
      {src && !videoUrl && (
        <img
          src={src}
          alt="Video thumbnail"
          className={cn(
            "absolute inset-0 w-full h-full object-cover transition-opacity duration-300",
            isHidden ? "opacity-0" : "opacity-100"
          )}
        />
      )}
    </>
  );
}