import { loggingService } from '../../services/logging';

export async function attemptAutoplay(videoElement: HTMLVideoElement, startMuted: boolean = false): Promise<boolean> {
  try {
    // Set initial muted state
    videoElement.muted = startMuted;
    await videoElement.play();
    return startMuted;
  } catch (error) {
    if (!startMuted) {
      loggingService.addLog({
        type: 'info',
        message: 'Unmuted autoplay failed, trying muted',
        data: { error }
      });

      try {
        // Fall back to muted autoplay
        videoElement.muted = true;
        await videoElement.play();
        return true; // Started muted
      } catch (secondError) {
        loggingService.addLog({
          type: 'error',
          message: 'All autoplay attempts failed',
          data: { error: secondError }
        });
      }
    }
    return true;
  }
}

export function initializeVideo(videoElement: HTMLVideoElement, src: string): void {
  videoElement.src = src;
  videoElement.muted = false; // Always start unmuted
  videoElement.load();
}