import { create } from 'zustand';
import { Hot50Entry } from '../types/hot50';
import { fetchHot50Entries } from '../services/hot50';
import { loggingService } from '../services/logging';

interface Hot50Store {
  entries: Hot50Entry[];
  isLoading: boolean;
  error: string | null;
  fetchEntries: () => Promise<void>;
}

export const useHot50Store = create<Hot50Store>((set) => ({
  entries: [],
  isLoading: false,
  error: null,
  fetchEntries: async () => {
    set({ isLoading: true, error: null });
    try {
      const entries = await fetchHot50Entries();
      set({ entries, isLoading: false });

      loggingService.addLog({
        type: 'success',
        message: 'Successfully fetched Hot50 entries',
        data: { entryCount: entries.length }
      });
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Failed to fetch entries';
      set({ error: message, isLoading: false });
      
      loggingService.addLog({
        type: 'error',
        message: 'Failed to fetch Hot50 entries',
        data: error
      });
    }
  }
}));