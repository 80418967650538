import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { verifyWorkbookAccess } from '../lib/workbook/sharing/access';
import { Editor } from '../components/workbook/Editor';
import { BackButton } from '../components/BackButton';
import { useWorkbookLoader } from '../lib/workbook/hooks/useWorkbookLoader';

export function PublicWorkbookPage() {
  const { hash, id } = useParams<{ hash: string; id: string }>();
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState(false);
  const { title, content, isLoading, error } = useWorkbookLoader(isVerified ? id : null);

  useEffect(() => {
    const verifyAccess = async () => {
      if (!hash || !id) {
        navigate('/');
        return;
      }

      const hasAccess = await verifyWorkbookAccess(id, hash);
      if (!hasAccess) {
        navigate('/');
        return;
      }

      setIsVerified(true);
    };

    verifyAccess();
  }, [hash, id, navigate]);

  if (!isVerified || error) {
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl font-bold text-red-500 mb-4">
          {error || 'Access denied'}
        </h2>
        <button
          onClick={() => navigate('/')}
          className="text-cream hover:text-cream-dark transition-colors"
        >
          Return to Home
        </button>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="animate-pulse space-y-8">
        <div className="h-12 bg-surface-secondary rounded-lg w-2/3" />
        <div className="h-[600px] bg-surface-secondary rounded-lg" />
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto">
      <BackButton />
      <h1 className="text-4xl font-bold text-text-primary mb-12">
        {title}
      </h1>
      <Editor 
        value={content} 
        onChange={() => {}} 
        readOnly={true}
      />
    </div>
  );
}