export class VideoProcessingError extends Error {
  constructor(
    message: string,
    public readonly code: string,
    public readonly details?: unknown
  ) {
    super(message);
    this.name = 'VideoProcessingError';
  }
}

export class VideoDownloadError extends VideoProcessingError {
  constructor(message: string, details?: unknown) {
    super(message, 'DOWNLOAD_ERROR', details);
    this.name = 'VideoDownloadError';
  }
}

export class VideoValidationError extends VideoProcessingError {
  constructor(message: string, details?: unknown) {
    super(message, 'VALIDATION_ERROR', details);
    this.name = 'VideoValidationError';
  }
}

export class VideoFormatError extends VideoProcessingError {
  constructor(message: string, details?: unknown) {
    super(message, 'FORMAT_ERROR', details);
    this.name = 'VideoFormatError';
  }
}