import { Music2, ExternalLink } from 'lucide-react';
import { Hot50Entry } from '../../types/hot50';
import { VideoBadge } from '../VideoBadge';

interface TrackCardMobileProps {
  entry: Hot50Entry;
  onTrackClick: (rank: number) => void;
  onTikTokClick: (e: React.MouseEvent<HTMLButtonElement>, url: string) => void;
}

export function TrackCardMobile({ entry, onTrackClick, onTikTokClick }: TrackCardMobileProps) {
  return (
    <div
      onClick={() => onTrackClick(entry.rank)}
      className="block bg-surface border border-border rounded-lg p-4 hover:shadow-lg transition-all cursor-pointer"
    >
      <div className="flex items-center space-x-4">
        <div className="flex-shrink-0 w-12 h-12 rounded-lg overflow-hidden">
          {entry.tiktok_artwork_url ? (
            <img
              src={entry.tiktok_artwork_url}
              alt={`${entry.track_name} artwork`}
              className="w-full h-full object-cover"
            />
          ) : (
            <div className="w-full h-full bg-surface-secondary flex items-center justify-center">
              <Music2 className="w-6 h-6 text-text-secondary" />
            </div>
          )}
        </div>
        
        <div className="flex-1 min-w-0">
          <div className="flex items-center space-x-3">
            <h3 className="text-lg font-medium text-text-primary truncate">
              {entry.track_name}
            </h3>
            {entry.videos && entry.videos.length > 0 && (
              <VideoBadge count={entry.videos.length} />
            )}
          </div>
          <p className="text-text-secondary truncate">{entry.artist_name}</p>
        </div>

        {entry.tiktok_sound_url && (
          <button
            onClick={(e) => onTikTokClick(e, entry.tiktok_sound_url!)}
            className="p-2 hover:bg-surface-secondary rounded-lg transition-colors"
          >
            <ExternalLink className="w-4 h-4 text-text-secondary" />
          </button>
        )}
      </div>
    </div>
  );
}