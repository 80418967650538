import { supabase } from '../../supabase';
import { Workbook } from '../types';
import { loggingService } from '../../../services/logging';

export async function fetchWorkbook(id: string): Promise<Workbook> {
  try {
    const { data, error } = await supabase
      .from('workbooks')
      .select('*')
      .eq('id', id)
      .single();

    if (error) throw error;
    if (!data) throw new Error('Workbook not found');

    return data;
  } catch (error) {
    loggingService.addLog({
      type: 'error',
      message: 'Failed to fetch workbook',
      data: { error, id }
    });
    throw error;
  }
}

export async function fetchAllWorkbooks(): Promise<Workbook[]> {
  try {
    const { data, error } = await supabase
      .from('workbooks')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) throw error;
    return data || [];
  } catch (error) {
    loggingService.addLog({
      type: 'error',
      message: 'Failed to fetch workbooks',
      data: error
    });
    throw error;
  }
}