import { Music2, Settings, Book } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { cn } from '../../lib/utils';
import { useWorkbookStore } from '../../store/workbooks';
import { MobileSidebarItem } from './MobileSidebarItem';
import { MobileSidebarHeader } from './MobileSidebarHeader';

interface MobileSidebarProps {
  isOpen: boolean;
  onClose: () => void;
}

export function MobileSidebar({ isOpen, onClose }: MobileSidebarProps) {
  const location = useLocation();
  const { workbooks } = useWorkbookStore();

  return (
    <>
      {/* Backdrop */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black/50 z-40 lg:hidden"
          onClick={onClose}
        />
      )}

      {/* Sidebar */}
      <div
        className={cn(
          "fixed inset-y-0 left-0 w-64 bg-background border-r border-border p-4 z-40 transform transition-transform duration-200 ease-in-out lg:translate-x-0",
          isOpen ? "translate-x-0" : "-translate-x-full"
        )}
      >
        <div className="flex flex-col h-full">
          <MobileSidebarHeader />

          {/* Navigation */}
          <nav className="space-y-1">
            <MobileSidebarItem
              to="/"
              icon={<Music2 className="w-4 h-4" />}
              label="Hot50 DE"
              isActive={location.pathname === "/"}
              onClick={onClose}
            />
          </nav>

          {/* Workbooks */}
          <div className="mt-6 flex-1 overflow-y-auto">
            <div className="px-2 py-1.5 text-text-secondary text-sm font-medium">
              Workbooks
            </div>
            <nav className="mt-1 space-y-1">
              {workbooks.map((workbook) => (
                <MobileSidebarItem
                  key={workbook.id}
                  to={`/workbook/${workbook.id}`}
                  icon={<Book className="w-4 h-4" />}
                  label={workbook.title}
                  isActive={location.pathname === `/workbook/${workbook.id}`}
                  onClick={onClose}
                />
              ))}
            </nav>
          </div>

          {/* Settings */}
          <MobileSidebarItem
            to="/settings"
            icon={<Settings className="w-4 h-4" />}
            label="Settings"
            isActive={location.pathname === "/settings"}
            onClick={onClose}
            className="mt-auto"
          />
        </div>
      </div>
    </>
  );
}