import { Descendant } from 'slate';
import { findSecondFriday } from './friday';
import { generateDateSequence } from './dates';
import { formatCalendarDate } from './format';
import { RELEASE_DATE_ROW } from './constants';
import { getContentType } from './content-types';

export function updateCalendarDates(content: Descendant[], releaseDate: string | null): Descendant[] {
  if (!releaseDate) return content;

  const secondFriday = findSecondFriday(releaseDate);
  if (!secondFriday) return content;

  // Generate all dates based on release date
  const dates = generateDateSequence(secondFriday);

  return content.map(node => {
    if (node.type === 'table') {
      const headerRow = node.children[0];
      const dataRows = node.children.slice(1);

      const updatedRows = dataRows.map((row: any, index) => {
        // Get existing content type from the row or use pattern
        const existingContentType = row.children[2]?.children[0]?.text;
        const contentType = existingContentType || getContentType(index);

        return {
          ...row,
          children: row.children.map((cell: any, cellIndex) => {
            if (cellIndex === 0 && dates[index]) {
              // Update date cell
              return {
                ...cell,
                children: [{ text: formatCalendarDate(dates[index]) }]
              };
            } else if (cellIndex === 2 && !existingContentType) {
              // Update content type cell only if it's empty
              return {
                ...cell,
                children: [{ text: contentType }]
              };
            }
            return cell;
          }),
          className: index === RELEASE_DATE_ROW ? 'bg-surface/30' : undefined
        };
      });

      return {
        ...node,
        children: [headerRow, ...updatedRows]
      };
    }
    return node;
  });
}