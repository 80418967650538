import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { ErrorDisplay } from '../components/ErrorDisplay';
import { VideoPlayer } from '../components/VideoPlayer';
import { AnalysisLog } from '../components/video/AnalysisLog';
import { AnalysisProgress } from '../components/video/AnalysisProgress';
import { GeminiResponse } from '../components/ai/GeminiResponse';
import { ThemeInput } from '../components/ai/ThemeInput';
import { BackButton } from '../components/BackButton';
import { useVideoAnalysis } from '../hooks/useVideoAnalysis';
import { loggingService } from '../services/logging';

interface VideoDetails {
  video_id: string;
  tiktok_video_url: string | null;
  tiktok_thumbnail_url: string | null;
  tiktok_file_url: string | null;
  drive_url: string | null;
  view_count: number | null;
  like_count: number | null;
  share_count: number | null;
  comment_count: number | null;
  description: string | null;
  author: string | null;
  author_profile_url: string | null;
  track_name: string | null;
  artist_name: string | null;
  content: string | null;
  mechanic: string | null;
}

export function VideoDetailPage() {
  const { videoId } = useParams();
  const [video, setVideo] = useState<VideoDetails | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const {
    isAnalyzing,
    contentAnalysis,
    mechanic,
    analysisLogs,
    handleAnalyze
  } = useVideoAnalysis(video);

  useEffect(() => {
    async function loadVideoDetails() {
      if (!videoId) {
        setError('No video ID provided');
        setIsLoading(false);
        return;
      }

      try {
        const { data, error: fetchError } = await supabase
          .from('video_details')
          .select('*')
          .eq('video_id', videoId)
          .single();

        if (fetchError) throw fetchError;
        if (!data) throw new Error('Video not found');

        setVideo(data);

        loggingService.addLog({
          type: 'success',
          message: 'Loaded video details',
          data: {
            videoId,
            hasContent: !!data.content,
            hasMechanic: !!data.mechanic
          }
        });
      } catch (error) {
        const message = error instanceof Error ? error.message : 'Failed to load video';
        setError(message);
        
        loggingService.addLog({
          type: 'error',
          message: 'Error fetching video details',
          data: { error, videoId }
        });
      } finally {
        setIsLoading(false);
      }
    }

    loadVideoDetails();
  }, [videoId]);

  if (isLoading) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="animate-pulse">
          <div className="flex space-x-6">
            <div className="w-[400px] h-[600px] bg-surface-secondary rounded-lg"></div>
            <div className="flex-1 space-y-6">
              <div className="h-8 bg-surface-secondary rounded w-1/3"></div>
              <div className="h-32 bg-surface-secondary rounded"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <ErrorDisplay error={new Error(error)} />;
  }

  if (!video) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8">
        <p className="text-text-primary">Video not found.</p>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <BackButton />
      <div className="flex space-x-6">
        <div className="w-[400px]">
          <VideoPlayer
            src={video.tiktok_file_url}
            poster={video.tiktok_thumbnail_url}
            autoPlay={true}
          />
        </div>

        <div className="flex-1 space-y-6">
          <div>
            <h1 className="text-2xl font-bold text-text-primary mb-2">
              {video.track_name}
            </h1>
            <p className="text-text-secondary">{video.artist_name}</p>
          </div>

          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-medium text-text-primary">Video Analysis</h2>
            </div>

            <AnalysisProgress isAnalyzing={isAnalyzing} />

            {(video.content || video.mechanic || contentAnalysis || mechanic) && (
              <>
                <GeminiResponse
                  content={contentAnalysis || video.content || ''}
                  mechanic={mechanic || video.mechanic || ''}
                  className="bg-surface-secondary rounded-lg p-4"
                />
                <ThemeInput 
                  onSubmit={handleAnalyze}
                  disabled={isAnalyzing}
                />
              </>
            )}

            <AnalysisLog 
              logs={analysisLogs}
              isAnalyzing={isAnalyzing}
            />
          </div>
        </div>
      </div>
    </div>
  );
}