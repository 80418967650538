import { Volume2, VolumeX } from 'lucide-react';
import { cn } from '../lib/utils';
import { useVideoPlayback } from '../hooks/useVideoPlayback';

interface VideoPlayerProps {
  src: string | null;
  poster?: string | null;
  className?: string;
  autoPlay?: boolean;
}

export function VideoPlayer({ src, poster, className, autoPlay = true }: VideoPlayerProps) {
  const {
    videoRef,
    isMuted,
    toggleMute
  } = useVideoPlayback({ src, autoPlay });

  return (
    <div className={cn("relative bg-black rounded-lg overflow-hidden group", className)}>
      {src ? (
        <>
          <video
            ref={videoRef}
            poster={poster}
            playsInline
            loop
            className="w-full h-full object-contain"
          >
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          
          <button
            onClick={toggleMute}
            className="absolute bottom-4 right-4 p-2 bg-black/50 rounded-full text-white hover:bg-black/70 transition-colors opacity-0 group-hover:opacity-100"
          >
            {isMuted ? (
              <VolumeX className="w-5 h-5" />
            ) : (
              <Volume2 className="w-5 h-5" />
            )}
          </button>
        </>
      ) : (
        <div className="absolute inset-0 flex items-center justify-center">
          <p className="text-text-secondary">Video not available</p>
        </div>
      )}
    </div>
  );
}