import { useEffect, useState } from 'react';
import { Settings, Database } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { UpdateHot50Button } from '../components/hot50/UpdateHot50Button';
import { UpdateVideosButton } from '../components/hot50/UpdateVideosButton';
import { ApifyTestFunction } from '../components/ApifyTestFunction';
import { GoogleCloudStatus } from '../components/settings/GoogleCloudStatus';
import { BulkAnalysis } from '../components/settings/BulkAnalysis';
import { AnalysisHistory } from '../components/video/AnalysisHistory';
import { LogSection } from '../components/LogSection';
import { BackButton } from '../components/BackButton';

interface ConnectionStatus {
  isConnected: boolean;
  error: string | null;
  lastChecked: Date;
}

export function SettingsPage() {
  const [supabaseStatus, setSupabaseStatus] = useState<ConnectionStatus>({
    isConnected: false,
    error: null,
    lastChecked: new Date()
  });

  const checkSupabaseConnection = async () => {
    try {
      const { data, error: hot50Error } = await supabase
        .from('hot50')
        .select('count');

      if (hot50Error) throw hot50Error;
      
      setSupabaseStatus({
        isConnected: true,
        error: null,
        lastChecked: new Date()
      });
    } catch (error) {
      setSupabaseStatus({
        isConnected: false,
        error: error instanceof Error ? error.message : 'Failed to connect to Supabase',
        lastChecked: new Date()
      });
    }
  };

  useEffect(() => {
    checkSupabaseConnection();
  }, []);

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <BackButton />
      <div className="flex items-center space-x-3 mb-8">
        <Settings className="w-6 h-6 text-cream" />
        <h1 className="text-2xl font-semibold text-text-primary">Settings</h1>
      </div>

      <div className="space-y-6">
        <div className="bg-surface border border-border rounded-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center space-x-3">
              <Database className="w-5 h-5 text-cream" />
              <h3 className="text-lg font-medium text-text-primary">Database Connection</h3>
            </div>
          </div>

          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <span className="text-text-secondary">Status:</span>
              <span className={supabaseStatus.isConnected ? "text-green-500" : "text-red-500"}>
                {supabaseStatus.isConnected ? "Connected" : "Disconnected"}
              </span>
            </div>

            {supabaseStatus.error && (
              <div className="text-red-500 text-sm">
                Error: {supabaseStatus.error}
              </div>
            )}

            <div className="flex items-center justify-between">
              <span className="text-text-secondary">Last checked:</span>
              <span className="text-text-secondary">
                {supabaseStatus.lastChecked.toLocaleTimeString()}
              </span>
            </div>
          </div>
        </div>

        <GoogleCloudStatus />
        <BulkAnalysis />
        <AnalysisHistory />

        <div className="bg-surface border border-border rounded-lg p-6">
          <h3 className="text-lg font-medium text-text-primary mb-4">Hot50 Management</h3>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <span className="text-text-secondary">Update Hot50 List:</span>
              <UpdateHot50Button />
            </div>
            <div className="flex items-center justify-between">
              <span className="text-text-secondary">Update Videos:</span>
              <UpdateVideosButton />
            </div>
          </div>
        </div>

        <ApifyTestFunction />
        <LogSection />
      </div>
    </div>
  );
}