import { Link } from 'react-router-dom';
import { cn } from '../../lib/utils';

interface MobileSidebarItemProps {
  to: string;
  icon: React.ReactNode;
  label: string;
  isActive: boolean;
  onClick?: () => void;
  className?: string;
}

export function MobileSidebarItem({
  to,
  icon,
  label,
  isActive,
  onClick,
  className
}: MobileSidebarItemProps) {
  return (
    <Link
      to={to}
      className={cn(
        "flex items-center space-x-2 px-2 py-1.5 rounded-md text-sm transition-colors",
        isActive ? "bg-surface text-text-primary" : "text-text-secondary hover:bg-surface/80",
        className
      )}
      onClick={onClick}
    >
      {icon}
      <span className="truncate">{label}</span>
    </Link>
  );
}