import { cn } from '../../lib/utils';

interface GeminiResponseProps {
  content: string;
  mechanic: string;
  className?: string;
}

export function GeminiResponse({ content, mechanic, className }: GeminiResponseProps) {
  // Clean and format content section
  const formatSection = (text: string) => {
    // Remove leading stars and empty lines
    const cleanText = text
      .replace(/^\s*[\*\s]*/, '')
      .trim();

    return cleanText
      .split('\n')
      .map(line => {
        // Convert headings
        if (line.match(/^#{1,3}\s/)) {
          const level = line.match(/^(#{1,3})\s/)?.[1].length || 1;
          const text = line.replace(/^#{1,3}\s/, '');
          const className = {
            1: 'text-xl font-bold mb-4 text-cream',
            2: 'text-lg font-semibold mb-3 text-cream-light',
            3: 'text-base font-medium mb-2 text-cream-light'
          }[level];
          return `<h${level} class="${className}">${text}</h${level}>`;
        }

        // Convert bold text
        line = line.replace(/\*\*(.*?)\*\*/g, '<strong class="text-cream">$1</strong>');

        // Convert bullet points
        if (line.match(/^[-*]\s/)) {
          return `<li class="ml-4 mb-2">${line.replace(/^[-*]\s/, '')}</li>`;
        }

        // Regular paragraphs (skip empty lines)
        return line.trim() ? `<p class="mb-4">${line}</p>` : '';
      })
      .filter(Boolean) // Remove empty lines
      .join('\n');
  };

  const formattedContent = formatSection(content);
  const formattedMechanic = formatSection(mechanic);

  return (
    <div className={cn("space-y-8", className)}>
      <div>
        <h2 className="text-xl font-bold mb-6 text-cream">Content-Analyse</h2>
        <div 
          className="prose prose-invert max-w-none text-text-secondary"
          dangerouslySetInnerHTML={{ __html: formattedContent }}
        />
      </div>

      <div>
        <h2 className="text-xl font-bold mb-6 text-cream">Marketing-Mechanik</h2>
        <div 
          className="prose prose-invert max-w-none text-text-secondary"
          dangerouslySetInnerHTML={{ __html: formattedMechanic }}
        />
      </div>
    </div>
  );
}