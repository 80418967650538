import { useState } from 'react';
import { MobileNav } from './MobileNav';
import { MobileSidebar } from './MobileSidebar';
import { MobileMenuButton } from './MobileMenuButton';
import { Footer } from '../Footer';

interface MobileLayoutProps {
  children: React.ReactNode;
}

export function MobileLayout({ children }: MobileLayoutProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="min-h-screen bg-background">
      <MobileMenuButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />
      <MobileNav />
      <MobileSidebar isOpen={isOpen} onClose={() => setIsOpen(false)} />

      <main className="pt-16 pb-16 px-4">
        <div className="max-w-7xl mx-auto">
          {children}
        </div>
      </main>

      <Footer />
    </div>
  );
}