import { useCallback } from 'react';
import { parse, isValid } from 'date-fns';
import { useWorkbookStore } from '../../../store/workbooks';
import { loggingService } from '../../../services/logging';
import { updateWorkbook } from '../database/operations';

export function useReleaseDate(workbookId: string) {
  const handleDateChange = useCallback(async (dateString: string) => {
    try {
      // Validate date format (DD.MM.YY)
      const parsedDate = parse(dateString, 'dd.MM.yy', new Date());
      if (!isValid(parsedDate)) {
        throw new Error('Invalid date format');
      }

      // Update workbook with new release date
      await updateWorkbook(workbookId, {
        release_date: dateString
      });

      loggingService.addLog({
        type: 'success',
        message: 'Updated release date',
        data: { workbookId, date: dateString }
      });
    } catch (error) {
      loggingService.addLog({
        type: 'error',
        message: 'Failed to update release date',
        data: { error, workbookId, date: dateString }
      });
      throw error;
    }
  }, [workbookId]);

  return { handleDateChange };
}