import { supabase } from '../lib/supabase';
import { Hot50Entry } from '../types/hot50';
import { loggingService } from './logging';

export async function fetchHot50Entries(): Promise<Hot50Entry[]> {
  try {
    // Get tracks from hot50 table
    const { data: hot50Data, error: hot50Error } = await supabase
      .from('hot50')
      .select('*')
      .order('rank');

    if (hot50Error) throw hot50Error;

    // Get videos for each entry
    const entries = await Promise.all(
      hot50Data.map(async (entry) => {
        const { data: videos, error: videosError } = await supabase
          .from('videos')
          .select('*')
          .eq('tiktok_sound_url', entry.tiktok_sound_url);

        if (videosError) {
          loggingService.addLog({
            type: 'error',
            message: `Failed to fetch videos for track ${entry.track_name}`,
            data: videosError
          });
          return {
            ...entry,
            videos: []
          };
        }

        return {
          ...entry,
          videos: videos || []
        };
      })
    );

    return entries;
  } catch (error) {
    loggingService.addLog({
      type: 'error',
      message: 'Failed to fetch Hot50 entries',
      data: error
    });
    throw error;
  }
}

export async function fetchTrackDetails(rank: number): Promise<Hot50Entry> {
  try {
    // Get track details
    const { data: trackData, error: trackError } = await supabase
      .from('hot50')
      .select('*')
      .eq('rank', rank)
      .single();

    if (trackError) throw trackError;
    if (!trackData) {
      throw new Error(`No track found at position ${rank}`);
    }

    // Get videos for this track
    const { data: videos, error: videosError } = await supabase
      .from('videos')
      .select('*')
      .eq('tiktok_sound_url', trackData.tiktok_sound_url)
      .order('created_at', { ascending: false });

    if (videosError) throw videosError;

    return {
      ...trackData,
      videos: videos || []
    };
  } catch (error) {
    loggingService.addLog({
      type: 'error',
      message: `Failed to fetch track details for rank ${rank}`,
      data: error
    });
    throw error;
  }
}