import { useParams } from 'react-router-dom';
import { Editor } from '../components/workbook/Editor';
import { WorkbookActions } from '../components/workbook/WorkbookActions';
import { BackButton } from '../components/BackButton';
import { TitleInput } from '../components/workbook/TitleInput';
import { DatePicker } from '../components/workbook/DatePicker';
import { useWorkbookLoader } from '../lib/workbook/hooks/useWorkbookLoader';
import { useWorkbookAutosave } from '../hooks/useWorkbookAutosave';

interface WorkbookPageProps {
  isPublic?: boolean;
}

export function WorkbookPage({ isPublic = false }: WorkbookPageProps) {
  const { id } = useParams();
  const { title, content, releaseDate, isLoading, error } = useWorkbookLoader(id);
  const { handleChange } = useWorkbookAutosave(id || '');

  const handleContentChange = (newContent: any) => {
    handleChange({ content: JSON.stringify(newContent) });
  };

  if (error) {
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl font-bold text-red-500 mb-4">{error}</h2>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="animate-pulse space-y-8">
        <div className="h-12 bg-surface-secondary rounded-lg w-2/3" />
        <div className="h-[600px] bg-surface-secondary rounded-lg" />
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto">
      <div className="flex items-center justify-between mb-12">
        <BackButton />
        <WorkbookActions 
          workbookId={id || ''} 
          isPublic={isPublic}
        />
      </div>

      <TitleInput
        value={title}
        onChange={(value) => handleChange({ title: value })}
        readOnly={isPublic}
        className="mb-6"
      />

      <DatePicker
        value={releaseDate || ''}
        onChange={(value) => handleChange({ release_date: value })}
        workbookId={id}
        content={content}
        onCalendarUpdate={handleContentChange}
      />

      <Editor
        key={id}
        value={content}
        onChange={handleContentChange}
        readOnly={isPublic}
      />
    </div>
  );
}