import { useState, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Calendar } from 'lucide-react';
import { format, parse, isValid, isFriday, setDay } from 'date-fns';
import { cn } from '../../lib/utils';
import { useReleaseDate } from '../../lib/workbook/hooks/useReleaseDate';
import { formatDateInput, validateDateFormat } from '../../lib/workbook/date';
import { updateCalendarDates } from '../../lib/workbook/calendar/table';
import "react-datepicker/dist/react-datepicker.css";

interface DatePickerProps {
  value: string;
  onChange: (value: string) => void;
  workbookId?: string;
  onCalendarUpdate?: (content: any) => void;
  content?: any;
}

export function DatePicker({ value, onChange, workbookId, onCalendarUpdate, content }: DatePickerProps) {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [inputValue, setInputValue] = useState('');
  const { handleDateChange } = useReleaseDate(workbookId || '');

  useEffect(() => {
    if (value && validateDateFormat(value)) {
      try {
        const parsed = parse(value, 'dd.MM.yy', new Date());
        if (isValid(parsed)) {
          const fridayDate = isFriday(parsed) ? parsed : setDay(parsed, 5);
          setSelectedDate(fridayDate);
          setInputValue(format(fridayDate, 'dd.MM.yy'));

          // Update calendar dates when release date changes
          if (content && onCalendarUpdate) {
            const updatedContent = updateCalendarDates(content, format(fridayDate, 'dd.MM.yy'));
            onCalendarUpdate(updatedContent);
          }
        }
      } catch (error) {
        console.warn('Failed to parse date:', error);
        setInputValue(value);
      }
    } else {
      setInputValue(value || '');
    }
  }, [value, content, onCalendarUpdate]);

  const handleDateSelect = async (date: Date | null) => {
    if (!date) return;

    const fridayDate = isFriday(date) ? date : setDay(date, 5);
    const formatted = format(fridayDate, 'dd.MM.yy');
    
    setSelectedDate(fridayDate);
    setInputValue(formatted);
    onChange(formatted);

    if (workbookId) {
      try {
        await handleDateChange(formatted);
        
        // Update calendar dates
        if (content && onCalendarUpdate) {
          const updatedContent = updateCalendarDates(content, formatted);
          onCalendarUpdate(updatedContent);
        }
      } catch (error) {
        console.error('Failed to save release date:', error);
      }
    }
  };

  return (
    <div className="relative inline-flex items-center">
      <input
        type="text"
        value={inputValue}
        onChange={(e) => {
          const formatted = formatDateInput(e.target.value);
          setInputValue(formatted);
          onChange(formatted);
          
          if (validateDateFormat(formatted) && content && onCalendarUpdate) {
            const updatedContent = updateCalendarDates(content, formatted);
            onCalendarUpdate(updatedContent);
          }
        }}
        placeholder="DD.MM.YY"
        className={cn(
          "bg-transparent outline-none w-24 text-text-secondary",
          inputValue && !validateDateFormat(inputValue) && "text-red-500"
        )}
        maxLength={8}
      />
      <ReactDatePicker
        selected={selectedDate}
        onChange={handleDateSelect}
        dateFormat="dd.MM.yy"
        filterDate={isFriday}
        customInput={
          <button
            className="p-1 hover:bg-surface-secondary rounded transition-colors ml-2"
            type="button"
          >
            <Calendar className="w-4 h-4 text-text-secondary" />
          </button>
        }
        popperContainer={({ children }) => (
          <div className="z-[9999]">{children}</div>
        )}
        popperPlacement="bottom-end"
        calendarClassName="bg-surface border border-border rounded-lg shadow-lg p-4"
        dayClassName={date => 
          cn(
            "text-text-secondary hover:bg-surface-secondary rounded-lg",
            selectedDate && format(date, 'dd.MM.yy') === format(selectedDate, 'dd.MM.yy') && 
            "bg-cream text-stone-dark"
          )
        }
        showPopperArrow={false}
      />
    </div>
  );
}