import { supabase } from '../../supabase';
import { WorkbookUpdate } from '../types';
import { loggingService } from '../../../services/logging';
import { nanoid } from 'nanoid';

export async function createWorkbook(data: WorkbookUpdate): Promise<string> {
  try {
    const id = nanoid();
    const now = new Date().toISOString();

    const { error } = await supabase
      .from('workbooks')
      .insert({
        id,
        title: data.title || 'Untitled Workbook',
        content: data.content || '[]',
        release_date: data.release_date || null,
        is_public: true, // Always create as public
        created_at: now,
        updated_at: now
      });

    if (error) throw error;
    return id;
  } catch (error) {
    loggingService.addLog({
      type: 'error',
      message: 'Failed to create workbook',
      data: error
    });
    throw error;
  }
}

export async function updateWorkbook(id: string, data: WorkbookUpdate): Promise<void> {
  try {
    const { error } = await supabase
      .from('workbooks')
      .update({
        ...data,
        updated_at: new Date().toISOString()
      })
      .eq('id', id);

    if (error) throw error;

    loggingService.addLog({
      type: 'success',
      message: 'Successfully updated workbook',
      data: { id, ...data }
    });
  } catch (error) {
    loggingService.addLog({
      type: 'error',
      message: 'Failed to update workbook',
      data: { error, id, ...data }
    });
    throw error;
  }
}

export async function deleteWorkbook(id: string): Promise<void> {
  try {
    const { error } = await supabase
      .from('workbooks')
      .delete()
      .eq('id', id);

    if (error) throw error;

    loggingService.addLog({
      type: 'success',
      message: 'Successfully deleted workbook',
      data: { id }
    });
  } catch (error) {
    loggingService.addLog({
      type: 'error',
      message: 'Failed to delete workbook',
      data: { error, id }
    });
    throw error;
  }
}