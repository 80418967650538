import { useState } from 'react';
import { Search, Loader, AlertTriangle } from 'lucide-react';
import { apifyClient } from '../services/apify/client';
import { loggingService } from '../services/logging';

export function ApifyTestFunction() {
  const [videoUrl, setVideoUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [result, setResult] = useState<any>(null);

  const handleTest = async () => {
    if (!videoUrl.trim()) {
      setError('Please enter a video URL');
      return;
    }

    setIsLoading(true);
    setError(null);
    setResult(null);

    try {
      const data = await apifyClient.getVideoDetails(videoUrl);
      setResult(data);
      
      loggingService.addLog({
        type: 'success',
        message: 'Successfully tested video URL with APIFY',
        data
      });
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Failed to fetch video data';
      setError(message);
      
      loggingService.addLog({
        type: 'error',
        message: 'Failed to test video URL with APIFY',
        data: { error, videoUrl }
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-surface border border-border rounded-lg p-6">
      <h3 className="text-lg font-medium text-text-primary mb-4">Test APIFY Actor</h3>
      
      <div className="space-y-4">
        <div className="relative">
          <input
            type="text"
            value={videoUrl}
            onChange={(e) => setVideoUrl(e.target.value)}
            placeholder="Enter TikTok video URL"
            className="w-full px-4 py-2 bg-surface-secondary rounded-lg text-text-primary placeholder:text-text-secondary focus:outline-none focus:ring-1 focus:ring-cream"
            disabled={isLoading}
          />
          <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-text-secondary" />
        </div>

        <button
          onClick={handleTest}
          disabled={isLoading || !videoUrl.trim()}
          className="w-full px-4 py-2 bg-cream text-stone-dark rounded-lg hover:bg-cream-dark transition-colors disabled:opacity-50 flex items-center justify-center space-x-2"
        >
          {isLoading ? (
            <>
              <Loader className="w-4 h-4 animate-spin" />
              <span>Processing...</span>
            </>
          ) : (
            <>
              <Search className="w-4 h-4" />
              <span>Test Video URL</span>
            </>
          )}
        </button>

        {error && (
          <div className="flex items-center space-x-2 text-red-500 text-sm">
            <AlertTriangle className="w-4 h-4" />
            <span>{error}</span>
          </div>
        )}

        {result && (
          <div className="mt-4 space-y-4">
            <div className="bg-surface-secondary rounded-lg p-4">
              <h4 className="text-md font-medium text-text-primary mb-2">Video Details</h4>
              <div className="space-y-2 text-sm text-text-secondary">
                <p>Description: {result.description}</p>
                <p>Views: {result.stats.viewCount.toLocaleString()}</p>
                <p>Likes: {result.stats.likeCount.toLocaleString()}</p>
                <p>Shares: {result.stats.shareCount.toLocaleString()}</p>
                <p>Comments: {result.stats.commentCount.toLocaleString()}</p>
                <p>Author: {result.author.nickname} (@{result.author.name})</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}