import { Trash2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useWorkbookStore } from '../../store/workbooks';
import { loggingService } from '../../services/logging';
import { DeleteDialog } from '../ui/DeleteDialog';

interface DeleteButtonProps {
  workbookId: string;
}

export function DeleteButton({ workbookId }: DeleteButtonProps) {
  const navigate = useNavigate();
  const deleteWorkbook = useWorkbookStore(state => state.deleteWorkbook);
  const [showDialog, setShowDialog] = useState(false);

  const handleDelete = async () => {
    try {
      await deleteWorkbook(workbookId);
      navigate('/');
      
      loggingService.addLog({
        type: 'success',
        message: 'Workbook deleted',
        data: { workbookId }
      });
    } catch (error) {
      loggingService.addLog({
        type: 'error',
        message: 'Failed to delete workbook',
        data: { error, workbookId }
      });
    }
  };

  return (
    <>
      <button
        onClick={() => setShowDialog(true)}
        className="flex items-center space-x-2 px-3 py-1.5 bg-surface rounded-lg text-red-500 hover:bg-surface-secondary transition-colors"
        title="Delete workbook"
      >
        <Trash2 className="w-4 h-4" />
      </button>

      <DeleteDialog
        isOpen={showDialog}
        onClose={() => setShowDialog(false)}
        onConfirm={() => {
          handleDelete();
          setShowDialog(false);
        }}
        title="Delete Workbook"
        message="Are you sure you want to delete this workbook? This action cannot be undone."
      />
    </>
  );
}