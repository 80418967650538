import { nanoid } from 'nanoid';

export interface Notification {
  id: string;
  type: 'info' | 'error';
  message: string;
  data?: unknown;
}

export function createNotification(
  type: 'info' | 'error',
  message: string,
  data?: unknown
): Notification {
  return {
    id: nanoid(),
    type,
    message,
    data
  };
}

export function isExpiredNotification(notification: Notification, expiryTime: number): boolean {
  const createdAt = new Date(notification.id).getTime();
  return Date.now() - createdAt > expiryTime;
}