import { useState } from 'react';
import { X } from 'lucide-react';
import { cn } from '../../lib/utils';
import { useTemplateCreation } from '../../lib/workbook/templates/hooks/useTemplateCreation';
import { defaultTemplates } from '../../lib/workbook/templates/defaults';

interface TemplateSelectorProps {
  onClose: () => void;
}

export function TemplateSelector({ onClose }: TemplateSelectorProps) {
  const [selectedId, setSelectedId] = useState<string>('artist-workbook');
  const { isCreating, createWorkbook } = useTemplateCreation();

  const handleCreate = async () => {
    try {
      await createWorkbook(selectedId);
      onClose();
    } catch {
      // Error is handled by the hook
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div 
        className="absolute inset-0 bg-black/50 backdrop-blur-sm"
        onClick={onClose}
      />
      
      <div className="relative bg-surface border border-border rounded-lg shadow-lg w-full max-w-2xl p-6 animate-in slide-in-from-bottom-4">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 p-1 hover:bg-surface-secondary rounded transition-colors"
        >
          <X className="w-4 h-4 text-text-secondary" />
        </button>

        <h2 className="text-xl font-semibold text-text-primary mb-6">
          Choose a Template
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
          {defaultTemplates.map((template) => (
            <button
              key={template.id}
              onClick={() => setSelectedId(template.id)}
              className={cn(
                "p-4 text-left border rounded-lg transition-colors",
                selectedId === template.id
                  ? "border-cream bg-surface-secondary"
                  : "border-border hover:border-cream/50"
              )}
            >
              <h3 className="font-medium text-text-primary mb-2">
                {template.name}
              </h3>
              <p className="text-sm text-text-secondary">
                {template.description}
              </p>
            </button>
          ))}
        </div>

        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 text-text-secondary hover:bg-surface-secondary rounded-lg transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={handleCreate}
            disabled={isCreating}
            className="px-4 py-2 bg-cream text-stone-dark rounded-lg hover:bg-cream-dark transition-colors disabled:opacity-50"
          >
            {isCreating ? 'Creating...' : 'Create Workbook'}
          </button>
        </div>
      </div>
    </div>
  );
}