import { create } from 'zustand';

interface ResponsiveStore {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  setDeviceType: (width: number) => void;
}

export const BREAKPOINTS = {
  mobile: 640,
  tablet: 1024,
  desktop: 1280
} as const;

export const useResponsiveStore = create<ResponsiveStore>((set) => ({
  isMobile: false,
  isTablet: false,
  isDesktop: true,
  setDeviceType: (width: number) => {
    set({
      isMobile: width < BREAKPOINTS.mobile,
      isTablet: width >= BREAKPOINTS.mobile && width < BREAKPOINTS.tablet,
      isDesktop: width >= BREAKPOINTS.tablet
    });
  }
}));

// Initialize responsive store with current window width
if (typeof window !== 'undefined') {
  useResponsiveStore.getState().setDeviceType(window.innerWidth);
  
  // Add resize listener
  window.addEventListener('resize', () => {
    useResponsiveStore.getState().setDeviceType(window.innerWidth);
  });
}