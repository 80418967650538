import { useCallback, useMemo } from 'react';
import { createEditor, Descendant } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import { FormatToolbar } from './FormatToolbar';
import { toggleMark } from './utils';
import { ElementRenderer, Leaf } from './ElementRenderer';

interface EditorProps {
  value: Descendant[];
  onChange: (value: Descendant[]) => void;
  readOnly?: boolean;
}

const defaultValue: Descendant[] = [
  {
    type: 'paragraph',
    children: [{ text: '' }]
  }
];

export function Editor({ value, onChange, readOnly = false }: EditorProps) {
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  const renderElement = useCallback((props: any) => {
    return <ElementRenderer {...props} value={value} />;
  }, [value]);

  const renderLeaf = useCallback((props: any) => {
    return <Leaf {...props} />;
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!event.ctrlKey || readOnly) return;

    switch (event.key) {
      case 'b': {
        event.preventDefault();
        toggleMark(editor, 'bold');
        break;
      }
      case 'i': {
        event.preventDefault();
        toggleMark(editor, 'italic');
        break;
      }
      case 'u': {
        event.preventDefault();
        toggleMark(editor, 'underline');
        break;
      }
    }
  };

  return (
    <div className="relative">
      <Slate editor={editor} initialValue={value} onChange={onChange}>
        {!readOnly && <FormatToolbar />}
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={handleKeyDown}
          className="min-h-[500px] focus:outline-none text-lg"
          placeholder={readOnly ? '' : 'Start writing...'}
          readOnly={readOnly}
        />
      </Slate>
    </div>
  );
}