import { addDays } from 'date-fns';
import { RELEASE_DATE_ROW } from './constants';

export function generateDateSequence(releaseDate: Date): Date[] {
  // Calculate start date (11 rows before release date)
  const startDate = addDays(releaseDate, -RELEASE_DATE_ROW);
  const dates: Date[] = [];

  // Generate 30 days total
  for (let i = 0; i < 30; i++) {
    dates.push(addDays(startDate, i));
  }

  return dates;
}