import { useState, useEffect } from 'react';
import { Clock, Search } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { formatDistanceToNow } from 'date-fns';
import { GeminiResponse } from '../ai/GeminiResponse';

interface AnalysisResult {
  video_id: string;
  content: string;
  mechanic: string;
  updated_at: string;
  description: string;
}

export function AnalysisHistory() {
  const [recentAnalyses, setRecentAnalyses] = useState<AnalysisResult[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchRecentAnalyses();
  }, []);

  const fetchRecentAnalyses = async () => {
    try {
      const { data, error } = await supabase
        .from('videos')
        .select('video_id, content, mechanic, updated_at, description')
        .not('content', 'is', null)
        .order('updated_at', { ascending: false })
        .limit(10);

      if (error) throw error;
      setRecentAnalyses(data || []);
    } catch (error) {
      console.error('Failed to fetch recent analyses:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredAnalyses = recentAnalyses.filter(analysis => 
    analysis.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    analysis.content?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    analysis.mechanic?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (isLoading) {
    return (
      <div className="bg-surface border border-border rounded-lg p-6">
        <div className="animate-pulse space-y-4">
          <div className="h-8 bg-surface-secondary rounded w-1/4"></div>
          <div className="h-32 bg-surface-secondary rounded"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-surface border border-border rounded-lg p-6">
      <h3 className="text-lg font-medium text-text-primary mb-4">Recent Video Analyses</h3>

      <div className="relative mb-6">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search in analyses..."
          className="w-full px-4 py-2 bg-surface-secondary rounded-lg text-text-primary placeholder:text-text-secondary focus:outline-none focus:ring-1 focus:ring-cream"
        />
        <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-text-secondary" />
      </div>

      <div className="space-y-8">
        {filteredAnalyses.map((analysis) => (
          <div key={analysis.video_id} className="space-y-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2 text-sm text-text-secondary">
                <Clock className="w-4 h-4" />
                <span>Analyzed {formatDistanceToNow(new Date(analysis.updated_at), { addSuffix: true })}</span>
              </div>
              <span className="text-sm text-text-secondary">ID: {analysis.video_id}</span>
            </div>
            
            {analysis.description && (
              <p className="text-text-secondary text-sm italic">
                "{analysis.description}"
              </p>
            )}

            <GeminiResponse
              content={analysis.content}
              mechanic={analysis.mechanic}
              className="bg-surface-secondary rounded-lg p-4"
            />
          </div>
        ))}

        {filteredAnalyses.length === 0 && (
          <div className="text-center text-text-secondary py-8">
            {searchTerm ? 'No analyses match your search.' : 'No recent analyses found.'}
          </div>
        )}
      </div>
    </div>
  );
}