export const CONTENT_TYPE_PATTERN = [
  'song-specific',
  'off-topic',
  'song-specific',
  'song-specific',
  'off-topic',
  'song-specific',
  'song-specific',
  'off-topic',
  'song-specific',
  'song-specific',
  'off-topic',
  'song-specific',
  'song-specific',
  'off-topic',
  'song-specific',
  'song-specific',
  'off-topic',
  'song-specific',
  'song-specific',
  'off-topic',
  'song-specific',
  'song-specific',
  'off-topic',
  'song-specific',
  'song-specific',
  'off-topic',
  'song-specific',
  'song-specific',
  'off-topic',
  'song-specific',
  'song-specific'
] as const;

export function getContentType(rowIndex: number): string {
  return CONTENT_TYPE_PATTERN[rowIndex] || '';
}