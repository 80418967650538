import { Copy, Check, Play, Heart, Share2, MessageCircle } from 'lucide-react';
import { useState } from 'react';
import { formatNumber } from '../../lib/utils';

interface VideoInfoCardProps {
  videoUrl: string;
  stats: {
    viewCount: number | null;
    likeCount: number | null;
    shareCount: number | null;
    commentCount: number | null;
  };
}

export function VideoInfoCard({ videoUrl, stats }: VideoInfoCardProps) {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(videoUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error('Failed to copy URL:', error);
    }
  };

  return (
    <div className="bg-surface-secondary rounded-b-lg p-3 -mt-2">
      <div className="flex items-center justify-between">
        <div className="grid grid-cols-4 gap-4 flex-1">
          <div className="flex items-center space-x-1.5">
            <Play className="w-4 h-4 text-text-secondary" />
            <span className="text-sm font-medium text-text-primary">
              {formatNumber(stats.viewCount)}
            </span>
          </div>
          <div className="flex items-center space-x-1.5">
            <Heart className="w-4 h-4 text-text-secondary" />
            <span className="text-sm font-medium text-text-primary">
              {formatNumber(stats.likeCount)}
            </span>
          </div>
          <div className="flex items-center space-x-1.5">
            <Share2 className="w-4 h-4 text-text-secondary" />
            <span className="text-sm font-medium text-text-primary">
              {formatNumber(stats.shareCount)}
            </span>
          </div>
          <div className="flex items-center space-x-1.5">
            <MessageCircle className="w-4 h-4 text-text-secondary" />
            <span className="text-sm font-medium text-text-primary">
              {formatNumber(stats.commentCount)}
            </span>
          </div>
        </div>
        <button
          onClick={handleCopy}
          className="p-1.5 hover:bg-surface rounded-lg transition-colors ml-2"
          title="Copy video URL"
        >
          {copied ? (
            <Check className="w-4 h-4 text-green-500" />
          ) : (
            <Copy className="w-4 h-4 text-text-secondary" />
          )}
        </button>
      </div>
    </div>
  );
}