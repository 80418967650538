import { loggingService } from '../../services/logging';

export interface GoogleCloudConfig {
  projectId: string;
  location: string;
  modelName: string;
}

export class GoogleCloudAuth {
  private static instance: GoogleCloudAuth;
  private config: GoogleCloudConfig | null = null;

  private constructor() {}

  static getInstance(): GoogleCloudAuth {
    if (!GoogleCloudAuth.instance) {
      GoogleCloudAuth.instance = new GoogleCloudAuth();
    }
    return GoogleCloudAuth.instance;
  }

  async initialize(): Promise<GoogleCloudConfig> {
    try {
      const projectId = import.meta.env.VITE_GOOGLE_CLOUD_PROJECT;
      const location = import.meta.env.VITE_GOOGLE_CLOUD_LOCATION || 'us-central1';
      const modelName = import.meta.env.VITE_GOOGLE_CLOUD_MODEL || 'gemini-1.5-flash-002';

      if (!projectId) {
        throw new Error('Google Cloud Project ID not configured');
      }

      this.config = { projectId, location, modelName };

      loggingService.addLog({
        type: 'success',
        message: 'Google Cloud configuration initialized',
        data: this.config
      });

      return this.config;
    } catch (error) {
      loggingService.addLog({
        type: 'error',
        message: 'Failed to initialize Google Cloud configuration',
        data: error
      });
      throw error;
    }
  }

  async getConfig(): Promise<GoogleCloudConfig> {
    if (!this.config) {
      return this.initialize();
    }
    return this.config;
  }

  async validateConfig(): Promise<boolean> {
    try {
      const config = await this.getConfig();
      
      // Validate required fields
      if (!config.projectId || !config.location || !config.modelName) {
        throw new Error('Missing required Google Cloud configuration');
      }

      loggingService.addLog({
        type: 'success',
        message: 'Google Cloud configuration validated',
        data: config
      });

      return true;
    } catch (error) {
      loggingService.addLog({
        type: 'error',
        message: 'Google Cloud configuration validation failed',
        data: error
      });
      return false;
    }
  }

  clearConfig(): void {
    this.config = null;
    loggingService.addLog({
      type: 'info',
      message: 'Google Cloud configuration cleared'
    });
  }
}

export const googleCloudAuth = GoogleCloudAuth.getInstance();