import { create } from 'zustand';
import { Workbook, WorkbookUpdate } from '../lib/workbook/types';
import { 
  createWorkbook, 
  updateWorkbook, 
  deleteWorkbook,
  fetchWorkbook,
  fetchAllWorkbooks
} from '../lib/workbook/database';
import { loggingService } from '../services/logging';

interface WorkbookStore {
  workbooks: Workbook[];
  isLoading: boolean;
  error: string | null;
  createWorkbook: (data: WorkbookUpdate) => Promise<string>;
  updateWorkbook: (id: string, data: WorkbookUpdate) => Promise<void>;
  deleteWorkbook: (id: string) => Promise<void>;
  fetchWorkbooks: () => Promise<void>;
  addWorkbook: (workbook: Workbook) => void;
}

export const useWorkbookStore = create<WorkbookStore>((set, get) => ({
  workbooks: [],
  isLoading: false,
  error: null,

  createWorkbook: async (data) => {
    try {
      const id = await createWorkbook(data);
      const workbook = await fetchWorkbook(id);
      
      set((state) => ({
        workbooks: [workbook, ...state.workbooks]
      }));

      loggingService.addLog({
        type: 'success',
        message: 'Created new workbook',
        data: { id }
      });

      return id;
    } catch (error) {
      loggingService.addLog({
        type: 'error',
        message: 'Failed to create workbook',
        data: error
      });
      throw error;
    }
  },

  updateWorkbook: async (id, data) => {
    try {
      await updateWorkbook(id, data);
      
      set((state) => ({
        workbooks: state.workbooks.map((w) =>
          w.id === id ? { ...w, ...data, updated_at: new Date().toISOString() } : w
        )
      }));
    } catch (error) {
      loggingService.addLog({
        type: 'error',
        message: 'Failed to update workbook',
        data: { error, id }
      });
      throw error;
    }
  },

  deleteWorkbook: async (id) => {
    try {
      await deleteWorkbook(id);
      
      set((state) => ({
        workbooks: state.workbooks.filter((w) => w.id !== id)
      }));

      loggingService.addLog({
        type: 'success',
        message: 'Deleted workbook',
        data: { id }
      });
    } catch (error) {
      loggingService.addLog({
        type: 'error',
        message: 'Failed to delete workbook',
        data: { error, id }
      });
      throw error;
    }
  },

  fetchWorkbooks: async () => {
    set({ isLoading: true, error: null });
    try {
      const workbooks = await fetchAllWorkbooks();
      set({ workbooks, isLoading: false });
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Failed to fetch workbooks';
      set({ error: message, isLoading: false });
      
      loggingService.addLog({
        type: 'error',
        message: 'Failed to fetch workbooks',
        data: error
      });
    }
  },

  addWorkbook: (workbook) => {
    set((state) => ({
      workbooks: [workbook, ...state.workbooks]
    }));
  }
}));