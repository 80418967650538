import { Template } from '../types';

export const DEFAULT_ARTIST_TEMPLATE: Template = {
  id: 'artist-workbook',
  name: 'Artist Workbook',
  description: 'Comprehensive template for planning music releases',
  content: JSON.stringify([
    {
      type: 'h2',
      children: [{ text: '🎵 Songname' }]
    },
    {
      type: 'paragraph',
      children: [{ text: '' }]
    },
    {
      type: 'h2',
      children: [{ text: '📆 Release Date' }]
    },
    {
      type: 'paragraph',
      children: [{ text: '' }]
    },
    {
      type: 'h2',
      children: [{ text: '📁 Master' }]
    },
    {
      type: 'paragraph',
      children: [{ text: '' }]
    },
    {
      type: 'h2',
      children: [{ text: '📀 Snippet' }]
    },
    {
      type: 'paragraph',
      children: [{ text: '' }]
    },
    {
      type: 'h2',
      children: [{ text: '🔗 Feedback Tool' }]
    },
    {
      type: 'paragraph',
      children: [{ text: '' }]
    },
    {
      type: 'h2',
      children: [{ text: '⭐️ Deine 5 favorite Winning Creatives' }]
    },
    {
      type: 'numbered-list',
      children: Array(5).fill({
        type: 'list-item',
        children: [{ text: '' }]
      })
    },
    {
      type: 'h2',
      children: [{ text: '💬 Die Meta Ebene deines Songs' }]
    },
    {
      type: 'paragraph',
      children: [{ text: '' }]
    },
    {
      type: 'h2',
      children: [{ text: '✏️ Lyrics' }]
    },
    {
      type: 'paragraph',
      className: 'bg-surface/50 p-4 rounded-lg',
      children: [{ text: '' }]
    },
    {
      type: 'h2',
      children: [{ text: '💡 Content Ideen' }]
    },
    {
      type: 'paragraph',
      children: [{ text: '' }]
    },
    {
      type: 'h2',
      children: [{ text: '🗓️ Content Calendar' }]
    },
    {
      type: 'table',
      children: [
        {
          type: 'table-row',
          children: [
            {
              type: 'table-cell',
              header: true,
              children: [{ text: 'Datum' }]
            },
            {
              type: 'table-cell',
              header: true,
              children: [{ text: 'Wochentag' }]
            },
            {
              type: 'table-cell',
              header: true,
              children: [{ text: 'Content Typ' }]
            },
            {
              type: 'table-cell',
              header: true,
              children: [{ text: 'Dropbox Link' }]
            },
            {
              type: 'table-cell',
              header: true,
              children: [{ text: 'Video Name' }]
            }
          ]
        }
      ]
    }
  ]),
  is_default: true,
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString()
};

export const defaultTemplates = [DEFAULT_ARTIST_TEMPLATE];