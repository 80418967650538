import { useSlate } from 'slate-react';
import { Bold, Italic, Underline, Heading1, Heading2, Heading3, List, ListOrdered } from 'lucide-react';
import { toggleMark, isMarkActive, toggleBlock, isBlockActive, getSelectionCoords } from './utils';
import { cn } from '../../lib/utils';
import { useEffect, useRef, useState } from 'react';

interface ToolbarButtonProps {
  format: string;
  icon: React.ReactNode;
  isBlock?: boolean;
}

function ToolbarButton({ format, icon, isBlock = false }: ToolbarButtonProps) {
  const editor = useSlate();
  const isActive = isBlock ? isBlockActive(editor, format) : isMarkActive(editor, format);

  return (
    <button
      onMouseDown={(event) => {
        event.preventDefault();
        if (isBlock) {
          toggleBlock(editor, format);
        } else {
          toggleMark(editor, format);
        }
      }}
      className={cn(
        "p-1.5 hover:bg-surface rounded transition-colors text-text-primary",
        isActive && "bg-surface text-cream"
      )}
      title={format}
    >
      {icon}
    </button>
  );
}

export function FormatToolbar() {
  const editor = useSlate();
  const toolbarRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState({ top: -10000, left: -10000 });
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const updateToolbarPosition = () => {
      const { selection } = editor;
      if (!selection || selection.anchor.offset === selection.focus.offset) {
        setIsVisible(false);
        return;
      }

      const coords = getSelectionCoords();
      if (!coords) {
        setIsVisible(false);
        return;
      }

      const toolbar = toolbarRef.current;
      if (!toolbar) return;

      const top = coords.top - toolbar.offsetHeight - 8;
      const left = coords.left + (coords.width / 2) - (toolbar.offsetWidth / 2);
      const boundedLeft = Math.max(8, Math.min(left, window.innerWidth - toolbar.offsetWidth - 8));
      
      setPosition({ top, left: boundedLeft });
      setIsVisible(true);
    };

    updateToolbarPosition();
    document.addEventListener('selectionchange', updateToolbarPosition);
    window.addEventListener('resize', updateToolbarPosition);
    
    return () => {
      document.removeEventListener('selectionchange', updateToolbarPosition);
      window.removeEventListener('resize', updateToolbarPosition);
    };
  }, [editor]);

  return (
    <div
      ref={toolbarRef}
      style={{
        position: 'fixed',
        top: `${position.top}px`,
        left: `${position.left}px`,
      }}
      className={cn(
        "flex items-center space-x-1 bg-background border border-border rounded-lg shadow-lg px-2 py-1 z-50",
        "transition-opacity duration-200",
        isVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      )}
    >
      <ToolbarButton format="bold" icon={<Bold className="w-4 h-4" />} />
      <ToolbarButton format="italic" icon={<Italic className="w-4 h-4" />} />
      <ToolbarButton format="underline" icon={<Underline className="w-4 h-4" />} />
      
      <div className="w-px h-4 bg-border mx-1" />
      
      <ToolbarButton format="h1" icon={<Heading1 className="w-4 h-4" />} isBlock />
      <ToolbarButton format="h2" icon={<Heading2 className="w-4 h-4" />} isBlock />
      <ToolbarButton format="h3" icon={<Heading3 className="w-4 h-4" />} isBlock />
      
      <div className="w-px h-4 bg-border mx-1" />
      
      <ToolbarButton format="bulleted-list" icon={<List className="w-4 h-4" />} isBlock />
      <ToolbarButton format="numbered-list" icon={<ListOrdered className="w-4 h-4" />} isBlock />
    </div>
  );
}