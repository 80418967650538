import axios from 'axios';
import { loggingService } from '../logging';

interface SpreadsheetEntry {
  rank: number;
  track_name: string;
  artist_name: string;
  tiktok_sound_url: string;
  tiktok_artwork_url: string | null;
}

interface VideoEntry {
  video_id: string;
  tiktok_video_url: string;
  tiktok_thumbnail_url: string | null;
  tiktok_file_url: string | null;
  tiktok_sound_url: string | null;
  description: string | null;
  like_count: number | null;
  comment_count: number | null;
  share_count: number | null;
  save_count: number | null;
  view_count: number | null;
  drive_url: string | null;
  content: string | null;
  topic: string | null;
  meta: string | null;
  mechanic: string | null;
  author: string;
  author_profile_url: string | null;
}

export class SpreadsheetService {
  private readonly HOT50_SPREADSHEET_ID = '1MlxknJ0rXlT8jGUMndhWMedVUVucqYAJhCpXi9o2xNc';
  private readonly HOT50_SHEET_NAME = 'Hot50';
  private readonly HOT50_RANGE = 'E2:J51'; // Position (E) to Song Link (J)

  private readonly VIDEOS_SPREADSHEET_ID = '1yXTT5EbZPLRI87Mljau7qUUted8ATFqq47RHwUVJytk';
  private readonly VIDEOS_SHEET_NAME = 'Videos';
  private readonly VIDEOS_RANGE = 'C2:W750'; // Sound URL (C) to Mechanic (W)

  async fetchHot50Data(): Promise<SpreadsheetEntry[]> {
    try {
      loggingService.addLog({
        type: 'info',
        message: 'Starting Hot50 spreadsheet data fetch'
      });

      const url = `https://docs.google.com/spreadsheets/d/${this.HOT50_SPREADSHEET_ID}/gviz/tq?tqx=out:json&sheet=${this.HOT50_SHEET_NAME}&range=${this.HOT50_RANGE}`;
      const response = await axios.get(url);
      
      // Extract JSON from response
      const jsonStr = response.data.replace('/*O_o*/\ngoogle.visualization.Query.setResponse(', '').slice(0, -2);
      const data = JSON.parse(jsonStr);

      // Map columns to our schema
      const entries: SpreadsheetEntry[] = data.table.rows.map((row: any) => ({
        rank: parseInt(row.c[0]?.v) || 0, // Position (E)
        track_name: row.c[1]?.v || 'Unknown Track', // Song Title (F)
        artist_name: row.c[2]?.v || 'Unknown Artist', // Artist (G)
        tiktok_artwork_url: row.c[4]?.v || null, // Cover Image (I)
        tiktok_sound_url: row.c[5]?.v || null // Song Link (J)
      }));

      // Validate entries
      const validEntries = entries.filter(entry => 
        entry.rank > 0 && 
        entry.rank <= 50 && 
        entry.track_name && 
        entry.artist_name
      );

      if (validEntries.length === 0) {
        throw new Error('No valid entries found in spreadsheet');
      }

      loggingService.addLog({
        type: 'success',
        message: 'Successfully fetched Hot50 spreadsheet data',
        data: { entriesCount: validEntries.length }
      });

      return validEntries;
    } catch (error) {
      loggingService.addLog({
        type: 'error',
        message: 'Failed to fetch Hot50 spreadsheet data',
        data: error
      });
      throw error;
    }
  }

  async fetchVideoData(): Promise<VideoEntry[]> {
    try {
      loggingService.addLog({
        type: 'info',
        message: 'Starting video data fetch'
      });

      const url = `https://docs.google.com/spreadsheets/d/${this.VIDEOS_SPREADSHEET_ID}/gviz/tq?tqx=out:json&sheet=${this.VIDEOS_SHEET_NAME}&range=${this.VIDEOS_RANGE}`;
      const response = await axios.get(url);
      
      // Extract JSON from response
      const jsonStr = response.data.replace('/*O_o*/\ngoogle.visualization.Query.setResponse(', '').slice(0, -2);
      const data = JSON.parse(jsonStr);

      // Map columns to our schema
      const entries: VideoEntry[] = data.table.rows
        .filter((row: any) => row.c[4]?.v) // Filter out rows without video URLs
        .map((row: any) => {
          const videoUrl = row.c[4]?.v || '';
          const videoId = videoUrl.split('/').pop()?.split('?')[0] || '';
          
          return {
            video_id: videoId,
            tiktok_video_url: videoUrl, // Video Link (G)
            tiktok_thumbnail_url: row.c[14]?.v || row.c[5]?.v || null, // Thumbnail URL (Q) or fallback to H
            tiktok_file_url: row.c[13]?.v || null, // File URL (P)
            tiktok_sound_url: row.c[0]?.v || null, // Sound URL (C)
            description: row.c[8]?.v || null, // Description (K)
            like_count: parseInt(row.c[9]?.v) || null, // Like Count (L)
            comment_count: parseInt(row.c[10]?.v) || null, // Comment Count (M)
            share_count: parseInt(row.c[11]?.v) || null, // Share Count (N)
            save_count: parseInt(row.c[12]?.v) || null, // Save Count (O)
            view_count: parseInt(row.c[15]?.v) || null, // View Count (R)
            drive_url: row.c[16]?.v || null, // Drive URL (S)
            content: row.c[17]?.v || null, // Content (T)
            topic: row.c[18]?.v || null, // Topic (U)
            meta: row.c[19]?.v || null, // Meta (V)
            mechanic: row.c[20]?.v || null, // Mechanic (W)
            author: row.c[3]?.v || 'Unknown', // Username (F)
            author_profile_url: row.c[6]?.v || null // Profile Picture (J)
          };
        });

      loggingService.addLog({
        type: 'success',
        message: 'Successfully fetched video data',
        data: { entriesCount: entries.length }
      });

      return entries;
    } catch (error) {
      loggingService.addLog({
        type: 'error',
        message: 'Failed to fetch video data',
        data: error
      });
      throw error;
    }
  }
}

export const spreadsheetService = new SpreadsheetService();