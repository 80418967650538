import { supabase } from '../../lib/supabase';
import { loggingService } from '../logging';
import { spreadsheetService } from './spreadsheet';

export class VideoImportService {
  async updateVideos(): Promise<void> {
    try {
      // Fetch video data from spreadsheet
      const videos = await spreadsheetService.fetchVideoData();

      loggingService.addLog({
        type: 'info',
        message: 'Starting video update process',
        data: { totalVideos: videos.length }
      });

      // Process videos in batches
      const batchSize = 50;
      for (let i = 0; i < videos.length; i += batchSize) {
        const batch = videos.slice(i, i + batchSize);
        
        // Update videos table
        const { error: videosError } = await supabase
          .from('videos')
          .upsert(batch.map(video => ({
            video_id: video.video_id,
            tiktok_video_url: video.tiktok_video_url,
            tiktok_thumbnail_url: video.tiktok_thumbnail_url,
            tiktok_file_url: video.tiktok_file_url, // Make sure this is mapped
            tiktok_sound_url: video.tiktok_sound_url,
            description: video.description,
            like_count: video.like_count,
            comment_count: video.comment_count,
            share_count: video.share_count,
            save_count: video.save_count,
            view_count: video.view_count,
            drive_url: video.drive_url,
            content: video.content,
            topic: video.topic,
            meta: video.meta,
            mechanic: video.mechanic,
            author: video.author,
            author_profile_url: video.author_profile_url
          })), {
            onConflict: 'video_id'
          });

        if (videosError) {
          loggingService.addLog({
            type: 'error',
            message: 'Failed to update videos batch',
            data: { error: videosError, batchSize: batch.length }
          });
          continue;
        }

        loggingService.addLog({
          type: 'success',
          message: `Updated batch of videos`,
          data: { 
            batchSize: batch.length,
            processedVideos: i + batch.length,
            remainingVideos: videos.length - (i + batch.length)
          }
        });

        // Add a small delay between batches
        if (i + batchSize < videos.length) {
          await new Promise(resolve => setTimeout(resolve, 500));
        }
      }

      loggingService.addLog({
        type: 'success',
        message: 'Successfully updated all videos',
        data: { totalVideos: videos.length }
      });
    } catch (error) {
      loggingService.addLog({
        type: 'error',
        message: 'Failed to update videos',
        data: error
      });
      throw error;
    }
  }
}

export const videoImportService = new VideoImportService();