import { Loader } from 'lucide-react';

interface AnalysisProgressProps {
  isAnalyzing: boolean;
}

export function AnalysisProgress({ isAnalyzing }: AnalysisProgressProps) {
  if (!isAnalyzing) return null;

  return (
    <div className="bg-surface-secondary rounded-lg p-4 mb-6">
      <div className="flex items-center space-x-3 mb-3">
        <Loader className="w-5 h-5 text-cream animate-spin" />
        <span className="text-text-primary">Analyzing video content...</span>
      </div>
      <div className="w-full bg-surface rounded-full h-2 overflow-hidden">
        <div className="bg-cream h-full w-full origin-left animate-[progress_2s_ease-in-out_infinite]" />
      </div>
    </div>
  );
}