// Use browser-native SubtleCrypto for hashing
async function sha256(message: string): Promise<string> {
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
}

export async function generatePublicHash(workbookId: string): Promise<string> {
  const secret = import.meta.env.VITE_HASH_SECRET || 'default-secret';
  const hash = await sha256(`${workbookId}-${secret}`);
  return hash.slice(0, 12); // Use first 12 chars for cleaner URLs
}

export async function verifyPublicHash(workbookId: string, hash: string): Promise<boolean> {
  const generatedHash = await generatePublicHash(workbookId);
  return generatedHash === hash;
}