import { Outlet } from 'react-router-dom';
import { Footer } from '../components/Footer';

export function PublicLayout() {
  return (
    <div className="min-h-screen bg-background">
      <main className="max-w-4xl mx-auto px-4 py-12">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}