import { useEffect } from 'react';
import { WorkbookUpdate } from '../lib/workbook/types';
import { autosaveManager } from '../lib/workbook/autosave';
import { useWorkbookStore } from '../store/workbooks';

export function useWorkbookAutosave(id: string) {
  const updateWorkbook = useWorkbookStore(state => state.updateWorkbook);

  useEffect(() => {
    return () => {
      // Cancel any pending autosaves when unmounting
      autosaveManager.cancelPendingSave();
    };
  }, []);

  const handleChange = (changes: WorkbookUpdate) => {
    // Schedule autosave
    autosaveManager.scheduleAutosave(id, changes);
    
    // Update local state immediately
    updateWorkbook(id, changes);
  };

  return {
    handleChange
  };
}