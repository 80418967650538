import { Music2, Settings, ChevronDown, Plus, Book } from 'lucide-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { cn } from '../lib/utils';
import { useWorkbookStore } from '../store/workbooks';
import { useEffect, useState } from 'react';
import { loggingService } from '../services/logging';
import { TemplateSelector } from './workbook/TemplateSelector';
import { useResponsive } from '../hooks/useResponsive';

interface SidebarItemProps {
  icon: React.ReactNode;
  label: string;
  href: string;
  active?: boolean;
}

function SidebarItem({ icon, label, href, active }: SidebarItemProps) {
  return (
    <Link
      to={href}
      className={cn(
        "flex items-center space-x-2 px-2 py-1.5 rounded-md text-sm transition-colors",
        active ? "bg-surface text-text-primary" : "text-text-secondary hover:bg-surface/80"
      )}
    >
      {icon}
      <span className="truncate">{label}</span>
    </Link>
  );
}

export function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { workbooks, createWorkbook, fetchWorkbooks } = useWorkbookStore();
  const [showTemplates, setShowTemplates] = useState(false);
  const { isMobile } = useResponsive();

  useEffect(() => {
    fetchWorkbooks().catch(error => {
      loggingService.addLog({
        type: 'error',
        message: 'Failed to fetch workbooks',
        data: error
      });
    });
  }, [fetchWorkbooks]);

  const handleCreateWorkbook = async (template?: { content: any }) => {
    try {
      const id = await createWorkbook({
        title: 'Untitled Workbook',
        content: template ? JSON.stringify(template.content) : undefined
      });
      navigate(`/workbook/${id}`);
      setShowTemplates(false);
    } catch (error) {
      loggingService.addLog({
        type: 'error',
        message: 'Failed to create workbook',
        data: error
      });
    }
  };

  if (isMobile) return null;

  return (
    <>
      <div className="w-64 h-screen fixed left-0 top-0 bg-background border-r border-border p-4 flex flex-col">
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-lg font-semibold text-cream-light">outnow.mov</h1>
          <button 
            onClick={() => setShowTemplates(true)}
            className="p-1 hover:bg-surface rounded transition-colors"
            title="Create new workbook"
          >
            <Plus className="w-4 h-4 text-text-secondary" />
          </button>
        </div>

        <div className="space-y-1">
          <SidebarItem
            icon={<Music2 className="w-4 h-4" />}
            label="Hot50 DE"
            href="/"
            active={location.pathname === '/'}
          />
        </div>

        <div className="mt-6 flex-1 overflow-y-auto">
          <div className="flex items-center justify-between px-2 py-1.5 text-text-secondary text-sm">
            <span className="font-medium">Workbooks</span>
            <button
              onClick={() => setShowTemplates(true)}
              className="p-1 hover:bg-surface rounded transition-colors"
              title="Create new workbook"
            >
              <Plus className="w-3 h-3" />
            </button>
          </div>
          <div className="mt-1 space-y-1">
            {workbooks.map((workbook) => (
              <SidebarItem
                key={workbook.id}
                icon={<Book className="w-4 h-4" />}
                label={workbook.title}
                href={`/workbook/${workbook.id}`}
                active={location.pathname === `/workbook/${workbook.id}`}
              />
            ))}
          </div>
        </div>

        <div className="mt-auto">
          <SidebarItem
            icon={<Settings className="w-4 h-4" />}
            label="Settings"
            href="/settings"
            active={location.pathname === '/settings'}
          />
        </div>
      </div>

      {showTemplates && (
        <TemplateSelector
          onSelect={(template) => handleCreateWorkbook(template)}
          onClose={() => setShowTemplates(false)}
        />
      )}
    </>
  );
}