import { useState } from 'react';
import { RefreshCw, Loader } from 'lucide-react';
import { videoImportService } from '../../services/hot50/video-import';
import { useHot50Store } from '../../store/hot50';
import { loggingService } from '../../services/logging';

export function UpdateVideosButton() {
  const [isUpdating, setIsUpdating] = useState(false);
  const fetchEntries = useHot50Store(state => state.fetchEntries);

  const handleUpdate = async () => {
    if (isUpdating) return;

    setIsUpdating(true);
    try {
      await videoImportService.updateVideos();
      await fetchEntries(); // Refresh the list after update

      loggingService.addLog({
        type: 'success',
        message: 'Videos updated successfully'
      });
    } catch (error) {
      loggingService.addLog({
        type: 'error',
        message: 'Failed to update videos',
        data: error
      });
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <button
      onClick={handleUpdate}
      disabled={isUpdating}
      className="flex items-center space-x-2 px-4 py-2 bg-cream text-stone-dark rounded-lg hover:bg-cream-dark transition-colors disabled:opacity-50"
    >
      {isUpdating ? (
        <>
          <Loader className="w-4 h-4 animate-spin" />
          <span>Updating Videos...</span>
        </>
      ) : (
        <>
          <RefreshCw className="w-4 h-4" />
          <span>Update Videos</span>
        </>
      )}
    </button>
  );
}