import { Menu, X } from 'lucide-react';

interface MobileMenuButtonProps {
  isOpen: boolean;
  onClick: () => void;
}

export function MobileMenuButton({ isOpen, onClick }: MobileMenuButtonProps) {
  return (
    <button
      onClick={onClick}
      className="lg:hidden fixed top-4 left-4 z-50 p-2 bg-surface rounded-lg"
      aria-label="Toggle menu"
    >
      {isOpen ? (
        <X className="w-6 h-6 text-text-primary" />
      ) : (
        <Menu className="w-6 h-6 text-text-primary" />
      )}
    </button>
  );
}