import { Outlet } from 'react-router-dom';
import { Navbar } from '../components/Navbar';
import { Sidebar } from '../components/Sidebar';
import { Footer } from '../components/Footer';
import { useResponsive } from '../hooks/useResponsive';

export function MainLayout() {
  const { isMobile } = useResponsive();

  return (
    <div className="min-h-screen bg-background">
      <Sidebar />
      <div className={`${isMobile ? '' : 'pl-64'}`}>
        <Navbar />
        <main className="pt-16 pb-16">
          <Outlet />
        </main>
        <Footer />
      </div>
    </div>
  );
}