import { useState, useEffect, useRef, useCallback } from 'react';
import { attemptAutoplay, initializeVideo } from '../lib/video/playback';
import { getBestVideoUrl } from '../lib/video/url-processor';
import { loggingService } from '../services/logging';

interface UseVideoPlaybackProps {
  src: string | null;
  autoPlay?: boolean;
}

export function useVideoPlayback({ src, autoPlay = true }: UseVideoPlaybackProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isMuted, setIsMuted] = useState(false);
  const hasAttemptedPlay = useRef(false);

  useEffect(() => {
    const initVideo = async () => {
      if (!videoRef.current || !src || hasAttemptedPlay.current) return;

      try {
        const videoUrl = getBestVideoUrl({ fileUrl: src }, false);
        initializeVideo(videoRef.current, videoUrl);

        if (autoPlay) {
          hasAttemptedPlay.current = true;
          const startedMuted = await attemptAutoplay(videoRef.current, false);
          setIsMuted(startedMuted);
        }
      } catch (error) {
        loggingService.addLog({
          type: 'error',
          message: 'Failed to initialize video',
          data: { error, src }
        });
      }
    };

    initVideo();

    return () => {
      hasAttemptedPlay.current = false;
    };
  }, [src, autoPlay]);

  const toggleMute = useCallback(() => {
    if (!videoRef.current) return;
    const newMutedState = !videoRef.current.muted;
    videoRef.current.muted = newMutedState;
    setIsMuted(newMutedState);
  }, []);

  return {
    videoRef,
    isMuted,
    toggleMute
  };
}