export const WEEKDAYS = [
  'Montag',
  'Dienstag',
  'Mittwoch',
  'Donnerstag',
  'Freitag',
  'Samstag',
  'Sonntag'
] as const;

// Row where release date should appear (0-based index, row 12)
export const RELEASE_DATE_ROW = 11;

// Date format used throughout the calendar
export const DATE_FORMAT = 'dd.MM.yy';