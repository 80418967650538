import { X } from 'lucide-react';
import { cn } from '../../lib/utils';

interface DeleteDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
}

export function DeleteDialog({ isOpen, onClose, onConfirm, title, message }: DeleteDialogProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Backdrop */}
      <div 
        className="absolute inset-0 bg-black/50 backdrop-blur-sm"
        onClick={onClose}
      />
      
      {/* Dialog */}
      <div className="relative bg-surface border border-border rounded-lg shadow-lg w-full max-w-md p-6 animate-in slide-in-from-bottom-4">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 p-1 hover:bg-surface-secondary rounded transition-colors"
        >
          <X className="w-4 h-4 text-text-secondary" />
        </button>

        <h2 className="text-xl font-semibold text-text-primary mb-4">
          {title}
        </h2>
        
        <p className="text-text-secondary mb-8">
          {message}
        </p>

        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 text-text-secondary hover:bg-surface-secondary rounded-lg transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}