import { useCallback } from 'react';
import { Element as SlateElement, Descendant } from 'slate';
import { useSlate } from 'slate-react';
import { useParams } from 'react-router-dom';
import { ContentCalendar } from './ContentCalendar';
import { DatePicker } from './DatePicker';
import { ReleaseDateDisplay } from './ReleaseDateDisplay';
import { cn } from '../../lib/utils';
import { parseWorkbookContent } from '../../lib/workbook/content';

interface ElementRendererProps {
  attributes: any;
  children: any;
  element: any;
  value: Descendant[];
  onChange?: (value: Descendant[]) => void;
}

export function ElementRenderer({ attributes, children, element, value, onChange }: ElementRendererProps) {
  const editor = useSlate();
  const { id: workbookId } = useParams();

  const getWorkbookReleaseDate = useCallback(() => {
    const content = editor.children
      .map(node => JSON.stringify(node))
      .join('');
    const parsedContent = parseWorkbookContent(content);
    return parsedContent.releaseDate;
  }, [editor.children]);

  // Handle different element types
  switch (element.type) {
    case 'h1':
      return <h1 {...attributes} className="text-3xl font-bold mb-4 text-text-primary">{children}</h1>;
    
    case 'h2':
      // Special handling for release date heading
      if (element.children[0]?.text === '📆 Release Date') {
        return (
          <div {...attributes}>
            <h2 className="text-2xl font-bold mb-3 text-text-primary">{children}</h2>
            <div contentEditable={false} className="mb-4">
              <DatePicker 
                value={getWorkbookReleaseDate() || ''}
                onChange={() => {}}
                workbookId={workbookId}
              />
              <ReleaseDateDisplay date={getWorkbookReleaseDate()} />
            </div>
          </div>
        );
      }
      return <h2 {...attributes} className="text-2xl font-bold mb-3 text-text-primary">{children}</h2>;
    
    case 'h3':
      return <h3 {...attributes} className="text-xl font-bold mb-2 text-text-primary">{children}</h3>;
    
    case 'numbered-list':
      return <ol {...attributes} className="list-decimal list-inside mb-4 text-text-primary">{children}</ol>;
    
    case 'bulleted-list':
      return <ul {...attributes} className="list-disc list-inside mb-4 text-text-primary">{children}</ul>;
    
    case 'list-item':
      return <li {...attributes} className="text-text-primary">{children}</li>;
    
    case 'table':
      return (
        <div {...attributes}>
          <ContentCalendar 
            value={value} 
            onUpdate={onChange || (() => {})} 
            releaseDate={getWorkbookReleaseDate()}
          />
          <table className="w-full border-collapse">
            <tbody>{children}</tbody>
          </table>
        </div>
      );
    
    case 'table-row':
      return (
        <tr {...attributes} className={cn(element.className)}>{children}</tr>
      );
    
    case 'table-cell':
      return element.header ? (
        <th {...attributes} className="border border-border p-2 text-text-primary bg-surface-secondary font-semibold">
          {children}
        </th>
      ) : (
        <td {...attributes} className="border border-border p-2 text-text-secondary">
          {children}
        </td>
      );
    
    default:
      return (
        <p 
          {...attributes} 
          className={cn(
            "mb-4 text-text-primary",
            element.className
          )}
        >
          {children}
        </p>
      );
  }
}

export function Leaf({ attributes, children, leaf }: any) {
  let el = children;

  if (leaf.bold) {
    el = <strong>{el}</strong>;
  }
  if (leaf.italic) {
    el = <em>{el}</em>;
  }
  if (leaf.underline) {
    el = <u>{el}</u>;
  }

  return <span {...attributes}>{el}</span>;
}