import { useState, useEffect, useRef } from 'react';
import { cn } from '../../lib/utils';
import { useDebounce } from '../../hooks/useDebounce';

interface TitleInputProps {
  value: string;
  onChange: (value: string) => void;
  readOnly?: boolean;
  className?: string;
}

export function TitleInput({ value, onChange, readOnly = false, className }: TitleInputProps) {
  const [localValue, setLocalValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);
  const debouncedOnChange = useDebounce(onChange, 500);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleChange = (newValue: string) => {
    setLocalValue(newValue);
    debouncedOnChange(newValue);
  };

  const handleFocus = () => {
    if (!readOnly && inputRef.current) {
      inputRef.current.select();
    }
  };

  return (
    <input
      ref={inputRef}
      type="text"
      value={localValue}
      onChange={(e) => handleChange(e.target.value)}
      onFocus={handleFocus}
      className={cn(
        "w-full text-4xl font-bold bg-transparent border-none outline-none",
        "text-text-primary focus:ring-0 placeholder:text-text-secondary/50",
        "transition-colors duration-200",
        readOnly && "cursor-default",
        className
      )}
      placeholder="Untitled Workbook"
      readOnly={readOnly}
    />
  );
}