import axios from 'axios';
import { loggingService } from '../logging';

const APIFY_API_TOKEN = 'apify_api_IJtIeRDqKdEP0UsHIQVBlPnfH8y78V322E8Y';
const ACTOR_ID = 'nCNiU9QG1e0nMwgWj';

export class ApifyClient {
  private static instance: ApifyClient;
  private readonly API_ENDPOINT = '/api/apify';

  private constructor() {}

  static getInstance(): ApifyClient {
    if (!ApifyClient.instance) {
      ApifyClient.instance = new ApifyClient();
    }
    return ApifyClient.instance;
  }

  async getVideoDetails(videoUrl: string) {
    try {
      loggingService.addLog({
        type: 'info',
        message: 'Starting APIFY video fetch',
        data: { 
          videoUrl,
          endpoint: this.API_ENDPOINT,
          actorId: ACTOR_ID
        }
      });

      const response = await axios.post(this.API_ENDPOINT, {
        videoUrl,
        token: APIFY_API_TOKEN,
        actorId: ACTOR_ID
      });

      loggingService.addLog({
        type: 'info',
        message: 'Received APIFY response',
        data: { 
          status: response.status,
          statusText: response.statusText,
          headers: response.headers
        }
      });

      if (!response.data) {
        throw new Error('Empty response from APIFY');
      }

      if (!Array.isArray(response.data) || response.data.length === 0) {
        throw new Error('No data returned from APIFY');
      }

      const videoData = response.data[0];

      loggingService.addLog({
        type: 'success',
        message: 'Successfully parsed APIFY response',
        data: videoData
      });

      return {
        description: videoData.text || videoData.desc || '',
        stats: {
          viewCount: videoData.stats?.playCount || videoData.playCount || 0,
          likeCount: videoData.stats?.diggCount || videoData.diggCount || 0,
          shareCount: videoData.stats?.shareCount || videoData.shareCount || 0,
          commentCount: videoData.stats?.commentCount || videoData.commentCount || 0
        },
        author: {
          id: videoData.authorMeta?.id || videoData.author?.id || '',
          name: videoData.authorMeta?.name || videoData.author?.uniqueId || '',
          nickname: videoData.authorMeta?.nickName || videoData.author?.nickname || ''
        }
      };
    } catch (error) {
      loggingService.addLog({
        type: 'error',
        message: 'Failed to fetch video data from APIFY',
        data: {
          error,
          videoUrl,
          errorMessage: error instanceof Error ? error.message : 'Unknown error',
          errorStack: error instanceof Error ? error.stack : undefined,
          axiosError: axios.isAxiosError(error) ? {
            status: error.response?.status,
            statusText: error.response?.statusText,
            data: error.response?.data,
            headers: error.response?.headers
          } : undefined
        }
      });
      throw error;
    }
  }
}

export const apifyClient = ApifyClient.getInstance();