import { Save } from 'lucide-react';
import { useState } from 'react';
import { saveAsTemplate } from '../../lib/workbook/templates/database';
import { useNotificationStore } from '../../store/notifications';

interface SaveTemplateButtonProps {
  workbookId: string;
  className?: string;
}

export function SaveTemplateButton({ workbookId, className }: SaveTemplateButtonProps) {
  const [isSaving, setIsSaving] = useState(false);
  const addNotification = useNotificationStore(state => state.addNotification);

  const handleSave = async () => {
    if (isSaving) return;

    setIsSaving(true);
    try {
      await saveAsTemplate(workbookId);
      
      addNotification({
        type: 'info',
        message: 'Current layout saved as Artist Workbook template'
      });
    } catch (error) {
      addNotification({
        type: 'error',
        message: 'Failed to save template',
        data: error
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <button
      onClick={handleSave}
      disabled={isSaving}
      className={`flex items-center justify-center px-3 py-1.5 bg-surface rounded-lg text-text-primary hover:bg-surface-secondary transition-colors ${className}`}
      title="Save as template"
    >
      <Save className="w-4 h-4" />
    </button>
  );
}