import { formatDisplayDate } from '../../lib/workbook/date';

interface ReleaseDateDisplayProps {
  date: string | undefined;
}

export function ReleaseDateDisplay({ date }: ReleaseDateDisplayProps) {
  if (!date) return null;

  const formattedDate = formatDisplayDate(date);
  if (!formattedDate) return null;

  return (
    <div className="mt-2">
      <span className="text-text-secondary text-sm">
        Release Date: {formattedDate}
      </span>
    </div>
  );
}